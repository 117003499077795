import React, { useEffect } from 'react';
import { NavLink, Route, Routes, useNavigate } from 'react-router-dom';
import cls from './SettingsPage.module.css';
import classNames from 'classnames';

import DormitoriesSettings from "../../widgets/DormitoriesSettings/DormitoriesSettings";
import FiltersSettings from "../../widgets/FiltersSettings/FiltersSettings";
import UsersSettings from "../../widgets/UsersSettings/UsersSettings";
import CreateUserPage from '../CreateUserPage/CreateUserPage';
import CreateFilterPage from '../CreateFilterPage/CreateFilterPage';
import { useDispatch } from 'react-redux';
import { logout} from "../../store/userSlice";
import axiosInstance from '../../http/axiosInstanse';
import CreateDormitoryPage from '../CreateDormitoryPage/CreateDormitoryPage';
import EditingDormitoryPage from '../EditingDormitory/EditingDormitoryPage';
import ChangeDormitory from '../../widgets/ChangeDormitory/ChangeDormitory';



const SettingsPage = () => {
	const navigate = useNavigate(); // Используем хук для навигации
	const dispatch = useDispatch(); // Используем хук для вызова экшнов в Redux

	

	const logoutUser = () => {
		localStorage.removeItem('token'); // Удаляем токен из localStorage
		axiosInstance.defaults.headers.common['Authorization'] = ''; // Удаляем токен из заголовка
		dispatch(logout()); // Вызываем Redux экшн для очистки состояния пользователя
		navigate('/login', { replace: true });
	}

	return (
		<div className={cls.container}>
			<h2 className={cls.title}>Системные настройки</h2>
			<div className={cls.content}>
				<div className={cls.menu}>
					<div className={cls.settings}>
						<NavLink
							to="/settings/dormitories"
							className={({ isActive }) =>
								classNames(cls.menuItem, { [cls.active]: isActive })
							}
						>
							Настройка общежитий
						</NavLink>
						<NavLink
							to="/settings/filters"
							className={({ isActive }) =>
								classNames(cls.menuItem, { [cls.active]: isActive })
							}
						>
							Наборы фильтров
						</NavLink>
						<NavLink
							to="/settings/users"
							className={({ isActive }) =>
								classNames(cls.menuItem, { [cls.active]: isActive })
							}
						>
							Пользователи
						</NavLink>
					</div>
					<button className={cls.menuItem} onClick={logoutUser} >Выйти</button>
				</div>
				<div className={cls.settingsContent}>
					<Routes>
						<Route path="dormitories" element={<DormitoriesSettings />} />
						<Route path="filters" element={<FiltersSettings />} />
						<Route path="filters/create" element={<CreateFilterPage />} />
						<Route path="dormitories/create" element={<CreateDormitoryPage />} />
						<Route path="dormitories/edit" element={<EditingDormitoryPage />} />
						<Route path="users" element={<UsersSettings />} />
						<Route path="users/create" element={<CreateUserPage />} />
						<Route path="dormitories/edit/change" element={<ChangeDormitory />} />
					</Routes>
				</div>
			</div>
		</div>
	);
};

export default SettingsPage;
