import { configureStore } from '@reduxjs/toolkit';

import studentsSlice from '../../store/studentsSlice';
import dormitorySlice from '../../store/dormitorySlice';
import userSlice from '../../store/userSlice';
import employeeSlice from '../../store/employeeSlice';
import { filtersApi } from '../../store/filtersApi';

const store = configureStore({
	reducer: {
		user: userSlice,
		students: studentsSlice,
		dormitories: dormitorySlice,
		employees: employeeSlice,
		[filtersApi.reducerPath]: filtersApi.reducer,
	},
	middleware: (getDefaultMiddleware) =>
		getDefaultMiddleware().concat([filtersApi.middleware]),
});

export default store;
