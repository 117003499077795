import { LoginForm } from '../widgets/LoginForm/LoginForm';
import SettingsPage from '../pages/SettingsPage/SettingsPage';
import HomeScreen from '../pages/HomeScreen/HomeScreen';
import AnalyticPage from '../pages/AnalyticPage/AnalyticPage';

export const AppRoutes = {
	MAIN: 'main',
	ANALYTIC: 'analytic',
	LOGIN: 'login',
	SETTINGS: 'settings',
};

export const RoutePath = {
	MAIN: '/',
	ANALYTIC: '/analytic',
	LOGIN: '/login',
	SETTINGS: '/settings/*',
};

// export const routeConfig = {
// 	[AppRoutes.MAIN]: {
// 		path: '/',
// 		element: <HomeScreen />,
// 	},
// 	[AppRoutes.LOGIN]: {
// 		path: '/login',
// 		element: <LoginForm />,
// 	},
// 	[AppRoutes.TEST]: {
// 		path: '/test',
// 		element: <div>TEST</div>,
// 	},
// 	[AppRoutes.SETTINGS]: {
// 		path: '/settings/*',
// 		element: <SettingsPage />,
// 	},
// };
export const publicRoutes = [
	{
		path: RoutePath.LOGIN,
		Element: <LoginForm />,
	},
];

export const studentRoutes = [
	{
		path: RoutePath.MAIN,
		Element: <HomeScreen />,
	},
	{
		path: RoutePath.SETTINGS,
		Element: <SettingsPage />,
	},
	{
		path: RoutePath.ANALYTIC,
		Element: <AnalyticPage />,
	},
];

// export const accountantRoutes = [
//     {
//         path: DOCUMENT_ROUTE,
//         Element: <Documents />
//     },
//     {
//         path: STAFF_ROUTE,
//         Element: <Staff />
//     }
// ]

// export const merchandiserRoutes = [
//     {
//         path: WAREHOUSE_ROUTE,
//         Element: <Warehouse />
//     },
//     {
//         path: DOCUMENT_MERCHANDISE_ROUTE,
//         Element: <DocumentsMerchandise />
//     }
// ]
