import { Button } from '@mui/material';
import React from 'react';
import cls from './SettingsLayout.module.css';
import { Link } from 'react-router-dom';

const SettingsLayout = ({ title, buttonText, children, path }) => {
	return (
		<div className={cls.settings}>
			<div className={cls.header}>
				<h3 className={cls.title}>{title}</h3>
				<Link to={path}>
					<Button
						variant="outlined"
						sx={{
							width: '220px',
							height: '50px',
							color: '#000',
							borderColor: '#000',
							textTransform: 'none',
							fontSize: '15px',
							'&:hover': {
								borderColor: 'var(--orange-color)',
							},
						}}
					>
						{buttonText}
					</Button>
				</Link>
			</div>
			<div className={cls.content}>{children}</div>
		</div>
	);
};

export default SettingsLayout;
