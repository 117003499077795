import React from 'react';
import cls from './AddSettingsLayout.module.css';
import ArrowBack from '@mui/icons-material/ArrowBackIos';
import { Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import CircularProgress from '@mui/material/CircularProgress';

const AddSettingsLayout = ({
	title,
	hasSaveButton,
	onSaveClick,
	children,
	isLoading = false,
}) => {
	const navigate = useNavigate();

	const handleBackClick = () => {
		navigate(-1);
	};

	return (
		<div className={cls.container}>
			<div className={cls.header}>
				<button className={cls.backBtn} onClick={handleBackClick}>
					<ArrowBack />
				</button>
				<h2 className={cls.title}>{title}</h2>
				{!isLoading && hasSaveButton && (
					<Button
						variant="outlined"
						type="button" // Обязательно указываем type="button"
						onClick={onSaveClick}
						sx={{
							width: '150px',
							height: '50px',
							color: '#000',
							borderColor: '#000',
							textTransform: 'none',
							fontSize: '15px',
							'&:hover': {
								borderColor: 'var(--orange-color)',
							},
						}}
					>
						Сохранить
					</Button>
				)}
				{isLoading && <CircularProgress />}
			</div>
			{children}
		</div>
	);
};

export default AddSettingsLayout;
