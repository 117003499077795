import React, { useState } from 'react';
import AddSettingsLayout from '../../components/AddSettingsLayout/AddSettingsLayout';
import cls from './CreateUserPage.module.css';
import { MenuItem, Select, TextField, Alert, AlertTitle } from '@mui/material';
import axiosInstanceForSettings from '../../http/axiosInstanceForSettings';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

const CreateUserPage = () => {
	const [formData, setFormData] = useState({
		username: '',
		password: '',
		access_permission: '0',
	});

	const [alert, setAlert] = useState({
		open: false,
		severity: 'success', // 'success' or 'error'
		message: '',
	});

	const navigate = useNavigate();

	const handleSaveClick = async () => {
		try {
			// Отправляем POST-запрос
			const response = await axiosInstanceForSettings.post(
				'auth/user',
				formData
			);
			console.log('Response:', response.data);
			setAlert({
				open: true,
				severity: 'success',
				message: 'Пользователь успешно создан.',
			});

			navigate(-1);

			// Добавьте здесь логику обработки успешного ответа
		} catch (error) {
			console.error('Error during user creation:', error.message);
			setAlert({
				open: true,
				severity: 'error',
				message: 'Что-то пошло не так. Пожалуйста, попробуйте еще раз.',
			});
		}
	};

	const handleChange = (event) => {
		const { name, value } = event.target;
		setFormData((prevData) => ({
			...prevData,
			[name]: value,
		}));
	};

	const handleCloseAlert = () => {
		setAlert({
			...alert,
			open: false,
		});
	};

	return (
		<AddSettingsLayout
			title="Добавление пользователя"
			hasSaveButton={true}
			onSaveClick={handleSaveClick}
		>
			<div className={cls.container}>
				<div className={cls.item}>
					<p className={cls.label}>Логин</p>
					<TextField
						name="username"
						placeholder="Введите ФИО"
						onChange={handleChange}
						value={formData.username}
						sx={{
							'& .MuiOutlinedInput-root': {
								'& fieldset': {
									borderColor: 'black',
									borderRadius: '10px',
								},
							},
						}}
					/>
				</div>
				{/* <div className={cls.item}>
					<p className={cls.label}>Логин</p>
					<TextField
						name="login"
						placeholder="Введите логин"
						onChange={handleChange}
						value={formData.login}
						sx={{
							'& .MuiOutlinedInput-root': {
								'& fieldset': {
									borderColor: 'black',
									borderRadius: '10px',
								},
							},
						}}
					/>
				</div> */}
				<div className={cls.item}>
					<p className={cls.label}>Пароль</p>
					<TextField
						name="password"
						placeholder="Введите пароль"
						onChange={handleChange}
						value={formData.password}
						sx={{
							'& .MuiOutlinedInput-root': {
								'& fieldset': {
									borderColor: 'black',
									borderRadius: '10px',
								},
							},
						}}
					/>
				</div>
				<div className={cls.item}>
					<p className={cls.label}>Роль</p>
					<Select
						name="access_permission"
						label="Age"
						onChange={handleChange}
						value={formData.access_permission}
						sx={{
							'& .MuiOutlinedInput-notchedOutline': {
								borderColor: 'black',
								borderRadius: '10px',
							},
							'& .MuiInputLabel-root': {
								color: 'black', // цвет текста внутри Select
							},
							'& .MuiSelect-icon': {
								color: 'black', // цвет стрелки внутри Select
							},
						}}
					>
						<MenuItem value={0}>Admin</MenuItem>
						<MenuItem value={1}>Moderator</MenuItem>
						<MenuItem value={2}>User</MenuItem>
					</Select>
				</div>
			</div>
			{alert.open && (
				<Alert onClose={handleCloseAlert} severity={alert.severity}>
					<AlertTitle>
						{alert.severity === 'success' ? 'Успех' : 'Ошибка'}
					</AlertTitle>
					{alert.message}
				</Alert>
			)}
		</AddSettingsLayout>
	);
};

export default CreateUserPage;
