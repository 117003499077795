import React, { useEffect, useState } from 'react';
import AddSettingsLayout from '../../components/AddSettingsLayout/AddSettingsLayout';
import s from './ChangeDormitory.module.css';
import { TextField, Button } from '@mui/material';

import { useDispatch, useSelector } from 'react-redux';
import { deleteRoom, fetchRooms, selectRooms, selectSelectedDormitory, updateDormitoryField } from '../../store/dormitorySlice';
import axiosInstance from '../../http/axiosInstanse';
import AddRoomInDormitoryChange from '../AddRoomModal/AddRoomInDormitoryChange';
import ConfirmationDialog from '../../components/ConfirmationDialog/ConfirmationDialog';
import { useNavigate } from 'react-router-dom';


const ChangeDormitory = () => {

    const navigate = useNavigate();
    
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [open, setOpen] = useState(false);
    const [currentFloor, setCurrentFloor] = useState(null);
    const [selectedRoomId, setSelectedRoomId] = useState(null);

    const dormitory = useSelector(selectSelectedDormitory)
    const rooms = useSelector(selectRooms)
    const dispatch = useDispatch();

    const floorsWithRooms = rooms.reduce((acc, room) => {
        // Инициализация массива для нового этажа, если он ещё не создан
        acc[room.floor] = acc[room.floor] || [];
        acc[room.floor].push(room);
        return acc;
    }, {});

    // Получение массива этажей с комнатами для рендеринга
    const floorsArray = Object.keys(floorsWithRooms).map(floor => ({
        floorNumber: floor,
        rooms: floorsWithRooms[floor]
    }));

    const handleFieldChange = (field) => (event) => {
        dispatch(updateDormitoryField({ field, value: event.target.value }));
    };

    const handleAddRoom = (floorNumber) => {
        setCurrentFloor(floorNumber);
        setIsModalOpen(true); // при нажатии на кнопку с плюсом открываем модальное окно
    };

    const handleDeleteRoom = () =>{
        dispatch(deleteRoom(selectedRoomId)); // Отправьте запрос на удаление
		setOpen(false);
    }
    const handleClickOpen = (roomId) => {
		setSelectedRoomId(roomId);
		setOpen(true);
	};
    const handleClose = (value) => {
		setOpen(false);
	};

    const handleModalClose = () => {
        setIsModalOpen(false); // функция для закрытия модального окна
    };
    const handleSaveDormitory = async () => {
		await axiosInstance.post('building', {
            Id: dormitory.Id,
            name: dormitory.name,
            adress: dormitory.adress
        })
        navigate(-1)
         
	};
    const handleRoomSave = async (roomDetails) => {
        await axiosInstance.put('room', {
            comment: roomDetails.comments,
            type: roomDetails.type,
            number: roomDetails.number,
            floor: roomDetails.floor,
            BuildingId: dormitory.Id,
            max_count_students: roomDetails.capacity
        }
        )
        dispatch(fetchRooms(dormitory.Id));
        setIsModalOpen(false)
    };


    return (
        <AddSettingsLayout title={`Редактирование общежития`} hasSaveButton={true} onSaveClick={handleSaveDormitory}>

            <div className={s.flexContainer}>
                <div className={s.fieldContainer}>
                    <p className={s.label}>Название общежития</p>
                    <TextField
                        variant="outlined"
                        value={dormitory.name}
                        onChange={handleFieldChange('name')}

                        margin="normal"
                        sx={{
                            minWidth: "478px",
                            '& .MuiOutlinedInput-root': {
                                '& fieldset': {
                                    borderColor: 'black',
                                    borderRadius: '10px',
                                },
                            },
                        }}
                    />
                    <p className={s.label}>Улица</p>
                    <TextField

                        variant="outlined"
                        value={dormitory.adress}
                        onChange={handleFieldChange('adress')}
                        margin="normal"
                        sx={{
                            minWidth: "478px",
                            '& .MuiOutlinedInput-root': {
                                '& fieldset': {
                                    borderColor: 'black',
                                    borderRadius: '10px',
                                },
                            },
                        }}
                    />
                    {/* <p className={s.label}>Название общежития</p>
            <TextField
                variant="outlined"
                fullWidth
                value={dormitory.description}
                onChange={handleFieldChange('description')}
                margin="normal"
                sx={{
                    '& .MuiOutlinedInput-root': {
                        '& fieldset': {
                            borderColor: 'black',
                            borderRadius: '10px',
                        },
                    },
                }}
            /> */}
                </div>
                <div>
                    <p className={s.label}>Этажи</p>
                    <div className={s.floorContainer}>
                        {floorsArray.map((floorData) => (
                            <div key={floorData.floorNumber} className={s.floor}>
                                <p>Этаж {floorData.floorNumber}</p>
                                <div className={s.roomsContainer}>
                                    {floorData.rooms.map((room, index) => (
                                        <Button key={index} onClick={() => handleClickOpen(room.Id)}
                                        sx={{
                                            flex: '0 0 auto', // Flex properties
                                            border: 1,
                                            borderColor: 'black',
                                            borderRadius: '20px',
                                            color: "black"
                                            // Другие стили
                                        }}>{room.number}</Button>
                                    ))}
                                    {/* Кнопка для добавления комнаты, предполагаем, что функция handleAddRoom определена */}
                                    <Button onClick={() => handleAddRoom(floorData.floorNumber)} 
                                    sx={{
                                        flex: '0 0 auto', // Flex properties
                                        border: 1,
                                        borderColor: 'black',
                                        borderRadius: '20px',
                                        color: "black"
                                        // Другие стили
                                    }}>+</Button>
                                </div>
                            </div>

                        ))}

                    </div>
                </div>
            </div>
            <AddRoomInDormitoryChange open={isModalOpen} onClose={handleModalClose} onSave={handleRoomSave} floor={currentFloor} />

            <ConfirmationDialog
				open={open}
				onClose={handleClose}
				onConfirm={handleDeleteRoom}
			/>
        </AddSettingsLayout>
    )
}

export default ChangeDormitory;