import React, { useState } from 'react';
import AddSettingsLayout from '../../components/AddSettingsLayout/AddSettingsLayout';
import cls from './CreateFilterPage.module.css';
import { Autocomplete, TextField, Alert } from '@mui/material';
import { transformFormData } from './transformData';
import { useAddFilterMutation } from '../../store/filtersApi';
import { useNavigate } from 'react-router-dom';

const countries = [
	{ label: 'Россия', value: 'Россия' },
	{ label: 'Казахстан', value: 'Казахстан' },
	{ label: 'Беларусь', value: 'Беларусь' },
	{ label: 'Киргизия', value: 'Киргизия' },
	{ label: 'Китай', value: 'Китай' },
	{ label: 'Япония', value: 'Япония' },
	{ label: 'Англия', value: 'Англия' },
];

const genders = [
	{ label: 'Мужской', value: 'М' },
	{ label: 'Женский', value: 'Ж' },
];

const eduType = [
	{ label: 'Очная', value: 'очная' },
	{ label: 'Заочная', value: 'заочная' },
	{ label: 'Очно-заочная', value: 'очно-заочная' },
];

const CreateFilterPage = () => {
	const [formData, setFormData] = useState({
		name_filter: '',
		gender: [],
		country: [],
		citizenship: [],
		form_study: [],
	});

	const [addFilter, result] = useAddFilterMutation();
	const navigate = useNavigate();
	const [errorMessage, setErrorMessage] = useState('');
	const [nameError, setNameError] = useState('');

	const handleInputChange = (field, value) => {
		setFormData((prevState) => ({
			...prevState,
			[field]: value,
		}));

		if (field === 'name_filter') {
			const regex = /[a-zA-Z]/;
			if (regex.test(value)) {
				setNameError('Название не должно содержать латиницу.');
			} else {
				setNameError('');
			}
		}
	};

	const handleSubmit = async (e) => {
		e.preventDefault();

		const isFormValid =
			formData.name_filter.trim() !== '' &&
			Object.keys(formData).some(
				(key) => key !== 'name_filter' && formData[key].length > 0
			);

		if (!isFormValid) {
			setErrorMessage(
				'Пожалуйста, заполните название и хотя бы одно поле фильтра.'
			);
			return;
		}

		if (nameError) {
			setErrorMessage(nameError);
			return;
		}

		setErrorMessage('');
		await addFilter(transformFormData(formData));
		if (!result.isError) {
			navigate(-1);
		}
	};

	return (
		<AddSettingsLayout
			title="Добавление наборов фильтров"
			hasSaveButton={true}
			onSaveClick={handleSubmit}
		>
			{errorMessage && <Alert severity="error">{errorMessage}</Alert>}
			<div className={cls.container}>
				<div className={cls.item}>
					<p className={cls.label}>Название</p>
					<TextField
						placeholder="Введите название"
						value={formData.name_filter}
						onChange={(e) => handleInputChange('name_filter', e.target.value)}
						error={!!nameError}
						helperText={nameError}
						sx={{
							'& .MuiOutlinedInput-root': {
								'& fieldset': {
									borderColor: 'black',
									borderRadius: '10px',
								},
							},
						}}
					/>
				</div>
				<div className={cls.item}>
					<p className={cls.label}>Пол</p>
					<Autocomplete
						multiple
						options={genders}
						getOptionLabel={(option) => option.label}
						value={formData.gender}
						onChange={(event, newValue) =>
							handleInputChange('gender', newValue)
						}
						disableCloseOnSelect
						renderInput={(params) => (
							<TextField {...params} label="Выберите пол" />
						)}
					/>
				</div>
				<div className={cls.item}>
					<p className={cls.label}>Страна</p>
					<Autocomplete
						multiple
						options={countries}
						getOptionLabel={(option) => option.label}
						value={formData.country}
						onChange={(event, newValue) =>
							handleInputChange('country', newValue)
						}
						disableCloseOnSelect
						renderInput={(params) => (
							<TextField {...params} label="Выберите страны" />
						)}
					/>
				</div>
				<div className={cls.item}>
					<p className={cls.label}>Гражданство</p>
					<Autocomplete
						multiple
						options={countries}
						getOptionLabel={(option) => option.label}
						value={formData.citizenship}
						onChange={(event, newValue) =>
							handleInputChange('citizenship', newValue)
						}
						disableCloseOnSelect
						renderInput={(params) => (
							<TextField {...params} label="Выберите страны" />
						)}
					/>
				</div>
				<div className={cls.item}>
					<p className={cls.label}>Форма обучения</p>
					<Autocomplete
						multiple
						options={eduType}
						getOptionLabel={(option) => option.label}
						value={formData.form_study}
						onChange={(event, newValue) =>
							handleInputChange('form_study', newValue)
						}
						renderInput={(params) => (
							<TextField {...params} label="Выберите форму обучения" />
						)}
					/>
				</div>
			</div>
		</AddSettingsLayout>
	);
};

export default CreateFilterPage;
