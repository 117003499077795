import React, { useState } from 'react';
import AddSettingsLayout from '../../components/AddSettingsLayout/AddSettingsLayout';
import { TextField, IconButton } from '@mui/material';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import s from './CreateDormitoryPage.module.css';
import axiosInstance from '../../http/axiosInstanse';
import { useNavigate } from 'react-router-dom';


const CreateDormitoryPage = () => {
    const [dormitoryName, setDormitoryName] = useState('');
    const [street, setStreet] = useState('');
    const [contacts, setContacts] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [description, setDescription] = useState('');
    const [floors, setFloors] = useState([{ floorNumber: '', rooms: '' }]);
    const navigate = useNavigate();


    const expandRooms = (rooms) => {
        return rooms
            .replace(/\s+/g, '') // Удаляем все пробелы
            .split(',') // Разделяем по запятым
            .map(r => {
                if (r.includes('-')) {
                    const [start, end] = r.split('-').map(Number);
                    return Array(end - start + 1).fill().map((_, idx) => start + idx);
                }
                return Number(r);
            })
            .flat()
            .filter((value, index, self) => self.indexOf(value) === index); // Удаление дубликатов
    };

    // Функция для обработки добавления новой строки этажа
    const handleAddFloor = () => {
        setFloors([...floors, { floorNumber: '', rooms: '' }]);
    };

    const handleRemoveFloor = (index) => {
        const newFloors = floors.filter((_, i) => i !== index);
        setFloors(newFloors);
    };
    // Функция для обработки сохранения данных
    const handleSave = async () => {
        setIsLoading(true); // Включаем индикатор загрузки
        const expandedFloors = floors.map(floor => ({
            ...floor,
            rooms: expandRooms(floor.rooms)
        }));

        const floorNumbers = expandedFloors.map(floor => floor.floorNumber);
        const hasDuplicateFloors = new Set(floorNumbers).size !== floorNumbers.length;

        if (hasDuplicateFloors) {
            console.error('Ошибка: Найдены дублирующиеся номера этажей.');
            // Здесь можно добавить логику для вывода сообщения об ошибке пользователю
            return; // Прекращаем выполнение функции
        }

        try {
        const dormitoryResponse = await axiosInstance.put('building', {
            name: dormitoryName,
            adress: street,
            description: description
        });

        const id = dormitoryResponse.data.Id
        console.log(id)
        for (let i in expandedFloors){
            for(let j in expandedFloors[i].rooms){
                const roomResponse =await axiosInstance.put('room',{
                    comment:"",
                    type:"-",
                    number:`${expandedFloors[i].rooms[j]}`,
                    floor:`${expandedFloors[i].floorNumber}`,
                    BuildingId:`${id}`,
                    max_count_students:"4"
                })
                
                
            }
        }
        navigate(-1);
        setIsLoading(false);
    }
        catch(e){
            console.log(e)
            setIsLoading(false);
        }
        // Здесь будет логика сохранения данных




    };

    return (
         <AddSettingsLayout title="Добавление Общежития" hasSaveButton={true} onSaveClick={handleSave} isLoading={isLoading}>

            <div className={s.formContainer}>
                <div className={s.lefftSide}>
                    <p className={s.label}>Название общежития</p>
                    <TextField
                        label="Название общежития"
                        variant="outlined"
                        value={dormitoryName}
                        onChange={(e) => setDormitoryName(e.target.value)}
                        fullWidth
                        margin="normal"
                        sx={{
                            '& .MuiOutlinedInput-root': {
                                '& fieldset': {
                                    borderColor: 'black',
                                    borderRadius: '10px',
                                },
                            },
                        }}
                    />
                    <p className={s.label}>Улица</p>
                    <TextField
                        label="Улица"
                        variant="outlined"
                        value={street}
                        onChange={(e) => setStreet(e.target.value)}
                        fullWidth
                        margin="normal"
                        sx={{
                            '& .MuiOutlinedInput-root': {
                                '& fieldset': {
                                    borderColor: 'black',
                                    borderRadius: '10px',
                                },
                            },
                        }}
                    />
                    {/* <p className={s.label}>Контакты</p>
                    <TextField
                        label="Контакты"
                        variant="outlined"
                        value={contacts}
                        onChange={(e) => setContacts(e.target.value)}
                        fullWidth
                        margin="normal"
                        sx={{
                            '& .MuiOutlinedInput-root': {
                                '& fieldset': {
                                    borderColor: 'black',
                                    borderRadius: '10px',
                                },
                            },
                        }}
                    /> */}
                    <p className={s.label}>Описание</p>
                    <TextField
                        label="Описание"
                        variant="outlined"
                        value={description}
                        onChange={(e) => setDescription(e.target.value)}
                        fullWidth
                        margin="normal"
                        sx={{
                            '& .MuiOutlinedInput-root': {
                                '& fieldset': {
                                    borderColor: 'black',
                                    borderRadius: '10px',
                                },
                            },
                        }}
                    />
                </div>
                <div className={s.rightSide}>
                    <div className={s.labelsFlor}>
                        <p className={s.label}>Этаж</p>
                        <p className={s.label}>Комнаты</p>
                    </div>
                    {floors.map((floor, index) => (
                        <div key={index} className={s.floorRow}>
                            <TextField
                                label="Этаж"
                                variant="outlined"
                                value={floor.floorNumber}
                                onChange={(e) => {
                                    const newFloors = [...floors];
                                    newFloors[index].floorNumber = e.target.value;
                                    setFloors(newFloors);
                                }}
                                margin="normal"
                                sx={{
                                    '& .MuiOutlinedInput-root': {
                                        '& fieldset': {
                                            borderColor: 'black',
                                            borderRadius: '10px',
                                        },
                                    },
                                }}
                            />
                            <TextField
                                label="Комнаты"
                                variant="outlined"
                                value={floor.rooms}
                                onChange={(e) => {
                                    const newFloors = [...floors];
                                    newFloors[index].rooms = e.target.value;
                                    setFloors(newFloors);
                                }}
                                margin="normal"
                                sx={{
                                    '& .MuiOutlinedInput-root': {
                                        '& fieldset': {
                                            borderColor: 'black',
                                            borderRadius: '10px',
                                        },
                                    },
                                }}
                            />
                            {floors.length > 1 && (
                                <IconButton className={s.removeButton} onClick={() => handleRemoveFloor(index)}>
                                    <RemoveCircleOutlineIcon className={s.icon} />
                                </IconButton>
                            )}
                        </div>
                    ))}
                    <IconButton className={s.addButton} onClick={handleAddFloor}>
                        <AddCircleOutlineIcon />
                    </IconButton>
                </div>
            </div>

        </AddSettingsLayout>)
}

export default CreateDormitoryPage;