import React, { useEffect, useState } from 'react';
import s from './DormitoryList.module.css';
import HalfPageLayout from '../../components/HalfPageLayout/HalfPageLayout';
import {
	Button,
	TextField,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	Typography,
} from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import RoomDetails from "./RoomDetails/RoomDetails";
import { useDispatch, useSelector } from 'react-redux';
import {
	deleteCurrentRoom,
	deleteRooms,
	fetchCurrentRoom,
	fetchDormitory,
	fetchRooms,

	selectCurrentRoom,
	selectDormitories,
	selectRooms,
} from '../../store/dormitorySlice';

const columns = [
	{ id: 'roomNumber', label: 'Номер комнаты', minWidth: 100 },
	{ id: 'floor', label: 'Этаж', minWidth: 60 },
	{ id: 'roomType', label: 'Тип комнаты', minWidth: 100 },
	{
		id: 'settlement',
		label: 'Поселение',
		minWidth: 100,
	},
];

const DormitoryList = () => {
	const [selectedDormitory, setSelectedDormitory] = useState(null);
	const [selectedRoom, setSelectedRoom] = useState(null);
	const dispatch = useDispatch();
	const dormitories = useSelector(selectDormitories);
	const rooms = useSelector(selectRooms);
	const currentRooms = useSelector(selectCurrentRoom);

	const usersId = dormitories.map((dormitory) => dormitory.name);


	useEffect(() => {
		dispatch(fetchDormitory())
		dispatch(deleteRooms());
		dispatch(deleteCurrentRoom());
	}, [dispatch]);


	const handleDormitoryChange = async (event, newValue) => {
		if (newValue && typeof newValue === 'object' && newValue.Id) {
			dispatch(fetchRooms(newValue.Id));
			setSelectedDormitory(newValue);
		} else {
			setSelectedDormitory(null);
			dispatch(deleteRooms());
		}
	};

	const handleOpenRoom = (room) => {
		dispatch(fetchCurrentRoom(room.Id));
		console.log(selectedRoom);
	};

	return (
		<HalfPageLayout title="Список общежитий">
			{currentRooms ? (
				<RoomDetails roomData={currentRooms} />
			) : (
				<>
					<div className={s.searchBlock}>
						<Autocomplete
							freeSolo
							options={dormitories}
							getOptionLabel={(option) => (option.name ? option.name : '')}
							value={selectedDormitory}
							onChange={handleDormitoryChange}
							renderInput={(params) => (
								<TextField
									{...params}
									label="Поиск"
									variant="outlined"
									sx={{ width: '322px' }}
								/>
							)}
						/>
						{/* <Button variant="contained" sx={{ width: '128px', background: '#FF7C45' }}>

            </Button> */}
					</div>
					{rooms ? (
						<TableContainer sx={{ maxHeight: 650 }}>
							<Table>
								<TableHead className={s.TableHead}>
									<TableRow>
										<TableCell></TableCell>
										{columns.map((column) => (
											<TableCell key={column.id} style={{ minWidth: column.minWidth }}>
												{column.label}
											</TableCell>
										))}
										<TableCell></TableCell>
									</TableRow>
								</TableHead>
								<TableBody>
									{rooms
										.map((room) => (
											<TableRow key={room.Id}>
												<TableCell>
													<div style={{
														backgroundColor: room.count_students == room.max_count_students ? "rgba(175, 194, 101, 1)" : "rgba(255, 239, 100, 1)",
														height: "25px",
														width: "25px",
														borderRadius: 50
													}}>

													</div>
												</TableCell>
												<TableCell>{room.number}</TableCell>
												<TableCell>{room.floor}</TableCell>
												<TableCell>{room.type}</TableCell>
												<TableCell>{`${room.count_students} / ${room.max_count_students}`}</TableCell>
												<TableCell>
													<Button
														variant="contained"
														sx={{background: '#FF7C45' }}
														onClick={() => handleOpenRoom(room)}
													>
														Открыть
													</Button>
												</TableCell>
											</TableRow>
										))}
								</TableBody>
							</Table>
						</TableContainer>
					) : (
						<Typography>Выберите общежитие для отображения данных</Typography>
					)}
				</>
			)}
		</HalfPageLayout>
	);
};

export default DormitoryList;
