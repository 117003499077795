import React, { useState } from 'react';
import { Dialog, DialogActions, DialogContent, DialogTitle, TextField, Button } from '@mui/material';

function AddRoomModal({ open, onClose, onSave }) {
    const [roomDetails, setRoomDetails] = useState({
        number: '',
        floor: '',
        capacity: '',
        type: '',
        comments: '',
    });

    const handleChange = (e) => {
        const { id, value } = e.target;
        setRoomDetails(prevDetails => ({ ...prevDetails, [id]: value }));
    };

    const handleSave = () => {
        onSave(roomDetails);
        onClose(); // Close the modal after saving
    };

    return (
        <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
            <DialogTitle>Добавление комнаты</DialogTitle>
            <DialogContent>
                <TextField autoFocus margin="dense" id="number" label="Номер комнаты" type="text" fullWidth value={roomDetails.number} onChange={handleChange} />
                <TextField margin="dense" id="floor" label="Этаж" type="number" fullWidth value={roomDetails.floor} onChange={handleChange} />
                <TextField margin="dense" id="capacity" label="Вместимость" type="number" fullWidth value={roomDetails.capacity} onChange={handleChange} />
                <TextField margin="dense" id="type" label="Тип комнаты" type="text" fullWidth value={roomDetails.type} onChange={handleChange} />
                <TextField margin="dense" id="comments" label="Комментарии" multiline rows={4} fullWidth value={roomDetails.comments} onChange={handleChange} />
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose}>Отмена</Button>
                <Button onClick={handleSave}>Сохранить</Button>
            </DialogActions>
        </Dialog>
    );
}

export default AddRoomModal;