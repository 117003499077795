import React, { useEffect, useState } from 'react';
import AddSettingsLayout from '../../components/AddSettingsLayout/AddSettingsLayout';
import s from './EditingDormitoryPage.module.css';
import { useDispatch, useSelector } from 'react-redux';
import { deleteRoom,  fetchRooms, selectRooms, selectSelectedDormitory } from '../../store/dormitorySlice';
import { Button, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import AddRoomModal from '../../widgets/AddRoomModal/AddRoomModal';
import axiosInstance from '../../http/axiosInstanse';
import { useNavigate } from 'react-router-dom';
import Sidebar from "../../widgets/Sidebar/Sidebar";
import DeleteIcon from '@mui/icons-material/DeleteForever';
import ConfirmationDialog from '../../components/ConfirmationDialog/ConfirmationDialog';

const EditingDormitoryPage = () => {
    const dispatch = useDispatch();

    const navigate = useNavigate();


    const dormitory = useSelector(selectSelectedDormitory)
    const rooms = useSelector(selectRooms)

    const [filteredRooms, setFilteredRooms] = useState([]);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [open, setOpen] = useState(false);
    const [selectedRoomId, setSelectedRoomId] = useState(null);

    const [searchQuery, setSearchQuery] = React.useState('');
    function isEmpty(obj) {
        return Object.keys(obj).length === 0;
    }

    const handleDeleteRoom = (roomId) => {
        // Здесь вы должны вызвать функцию удаления из вашего стейта или отправить запрос на сервер для удаления
        console.log('Удаляем комнату с ID:', roomId);
        // Например, dispatch(deleteRoomAction(roomId));
    };

    useEffect(() => {
        if (isEmpty(dormitory)) {

            navigate('settings', { replace: true })
            setIsLoading(false)
        }
        dispatch(fetchRooms(dormitory.Id));
        setIsLoading(false)
    }, [dispatch, dormitory.Id]);

    useEffect(() => {
        // Фильтрация комнат в зависимости от запроса поиска
        if (searchQuery) {
            const searchLower = searchQuery.toLowerCase();
            const filtered = rooms.filter(room =>
                room.number.toString().toLowerCase().includes(searchLower)
            );
            setFilteredRooms(filtered);
        } else {
            setFilteredRooms(rooms);
        }
    }, [rooms, searchQuery]);


    const handleModalClose = () => setIsModalOpen(false);
    const handleModalOpen = () => setIsModalOpen(true);


    const handleClickOpen = (roomId) => {
		setSelectedRoomId(roomId);
		setOpen(true);
	};

	const handleRemoveClick = () => {
		dispatch(deleteRoom(selectedRoomId)); // Отправьте запрос на удаление
		setOpen(false);
	};

	const handleClose = (value) => {
		setOpen(false);
	};

    const handleRoomSave = async (roomDetails) => {
        await axiosInstance.put('room', {
            comment: roomDetails.comments,
            type: roomDetails.type,
            number: roomDetails.number,
            floor: roomDetails.floor,
            BuildingId: dormitory.Id,
            max_count_students: roomDetails.capacity
        }
        )
        dispatch(fetchRooms(dormitory.Id));
        setIsModalOpen(false)
    };

    const handleSearchChange = (event) => {
        setSearchQuery(event.target.value);
    };

    if (isLoading) {
        return <>
            <div className="app">
                <Sidebar />
                <div className="app__content">Загрузка...</div>
            </div>
        </>;
    }

    return (
        <AddSettingsLayout title={`${dormitory.name}`} hasSaveButton={false} path={"change"}>
            <div className={s.labelContainer}>
                <span className={s.labelName}>Улица:</span> <span className={s.label}>{dormitory.adress}</span>
            </div>

            <div className={s.searchAddContainer}>
                <TextField
                    sx={{
                        '& .MuiOutlinedInput-root': {
                            '& fieldset': {
                                borderColor: 'black',
                                borderRadius: '10px',
                            },
                        },
                        maxHeight: 49,
                        minWidth: 323
                    }}
                    value={searchQuery}
                    onChange={handleSearchChange}
                    placeholder="Напишите номер комнаты"
                    InputProps={{
                        startAdornment: <SearchIcon />
                    }}
                />
                <Button
                    variant="outlined"
                    onClick={handleModalOpen}
                    sx={{
                        minHeight: 50,
                        minWidth: 223,
                        marginLeft: '8px',
                        color: '#000',
                        borderColor: '#000',
                        textTransform: 'none',
                        fontSize: '15px',
                        '&:hover': {
                            borderColor: 'var(--orange-color)',
                        },
                    }}
                >
                    Добавить комнату
                </Button>
            </div>
            
            <TableContainer component={Paper} className={s.tableContainer} sx={{ borderRadius: 2 }}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>Этаж</TableCell>
                            <TableCell>Номер комнаты</TableCell>
                            <TableCell>Вместимость</TableCell>
                            <TableCell>Доступность</TableCell>
                            <TableCell>Комментарии</TableCell>
                            <TableCell></TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {/* Здесь должен быть map для отображения данных комнат, предположим у вас есть массив rooms */}
                        {filteredRooms.map((room) => (
                            <TableRow key={room.Id}>
                                <TableCell>{room.floor}</TableCell>
                                <TableCell>{room.number}</TableCell>
                                <TableCell>{room.max_count_students}</TableCell>
                                <TableCell>{room.max_count_students - room.count_students}</TableCell>
                                <TableCell>{room.comment}</TableCell>
                                <TableCell>
                                    <Button onClick={() => handleClickOpen(room.Id)} color="error">
                                        <DeleteIcon />
                                    </Button>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>

            <AddRoomModal open={isModalOpen} onClose={handleModalClose} onSave={handleRoomSave} />

            <ConfirmationDialog
				open={open}
				onClose={handleClose}
				onConfirm={handleRemoveClick}
			/>
        </AddSettingsLayout>)
}

export default EditingDormitoryPage;