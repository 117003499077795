import React, { useEffect } from 'react';
import cls from './StudentInfo.module.css';
import classNames from 'classnames';
import { Button } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { putStudent, selectCurrentRoom } from '../../store/dormitorySlice';
import axiosInstance from '../../http/axiosInstanse';
import { updateStatusId } from '../../store/studentsSlice';

const StudentInfo = (props) => {
	const {
		form_study,
		citizenship,
		first_student_email,
		enrollment,
		contact_phone_number,
		birth_date,
		id,
		room,
		floor,
		adress
	} = props.student;
	

	
	const currentRoom = useSelector(selectCurrentRoom);
	const dispatch = useDispatch();

	const handleButtonClick = async () => {
		await axiosInstance.put(`/student/${id}`, {
			room_id: `${currentRoom.Id}`,
		});
		dispatch(putStudent(props.student));
		dispatch(updateStatusId({ id: props.student.id, StatusId: 5 }));
		
	};

	return (
		<div className={cls.studentInfoContainer}>
			<p className={cls.studentInfo}>
				Форма обучения:{' '}
				<span className={classNames(cls.studentInfo, cls.studentInfoAccent)}>
					{form_study}
				</span>
			</p>
			<p className={cls.studentInfo}>
				Гражданство:{' '}
				<span className={classNames(cls.studentInfo, cls.studentInfoAccent)}>
					{citizenship}
				</span>
			</p>
			<p className={cls.studentInfo}>
				Email:{' '}
				<span className={classNames(cls.studentInfo, cls.studentInfoAccent)}>
					{first_student_email}
				</span>
			</p>
			{/* <p className={cls.studentInfo}>
				Зачисление:{' '}
				<span className={classNames(cls.studentInfo, cls.studentInfoAccent)}>
					{enrollment}
				</span>
			</p> */}
			<p className={cls.studentInfo}>
				Номер телефона:{' '}
				<span className={classNames(cls.studentInfo, cls.studentInfoAccent)}>
					{contact_phone_number}
				</span>
			</p>
			<p className={cls.studentInfo}>
				Дата рождения:{' '}
				<span className={classNames(cls.studentInfo, cls.studentInfoAccent)}>
					{birth_date.split(' ')[0]}
				</span>
			</p>
			<p className={cls.studentInfo}>
				Общежитие:{' '}
				<span className={classNames(cls.studentInfo, cls.studentInfoAccent)}>
					{adress!==''?adress:"—"}
				</span>
			</p>
			<p className={cls.studentInfo}>
				Этаж:{' '}
				<span className={classNames(cls.studentInfo, cls.studentInfoAccent)}>
					{floor!==''?floor:"—"}
				</span>
			</p>
			<p className={cls.studentInfo}>
				Комната:{' '}
				<span className={classNames(cls.studentInfo, cls.studentInfoAccent)}>
					{room!==''?room:"—"}
				</span>
			</p>

			<Button
				variant="contained"
				sx={{ background: '#FF7C45' }}
				disabled={
					currentRoom &&
					currentRoom.Students.length < currentRoom.max_count_students &&
					!currentRoom.Students.some(
						(studentInRoom) => studentInRoom.id === props.student.id
					) &&
					props.student.StatusId !== 5
						? false
						: true
				}
				onClick={handleButtonClick}
			>
				Заселить
			</Button>
		</div>
	);
};

export default StudentInfo;
