import React, { useState, useEffect } from 'react';
import {
	Modal,
	Box,
	TextField,
	Button,
	Autocomplete,
	Typography,
} from '@mui/material';

const style = {
	position: 'absolute',
	top: '50%',
	left: '50%',
	transform: 'translate(-50%, -50%)',
	width: 400,
	bgcolor: 'background.paper',
	boxShadow: 24,
	p: 4,
};

const countries = [
	{ label: 'Россия', value: 'Россия' },
	{ label: 'Казахстан', value: 'Казахстан' },
	{ label: 'Беларусь', value: 'Беларусь' },
	{ label: 'Киргизия', value: 'Киргизия' },
	{ label: 'Китай', value: 'Китай' },
	{ label: 'Япония', value: 'Япония' },
	{ label: 'Англия', value: 'Англия' },
];

const genders = [
	{ label: 'Мужской', value: 'М' },
	{ label: 'Женский', value: 'Ж' },
];

const eduType = [
	{ label: 'Очная', value: 'очная' },
	{ label: 'Заочная', value: 'заочная' },
	{ label: 'Очно-заочная', value: 'очно-заочная' },
];

const EditFilterModal = ({ open, handleClose, filter, handleSave }) => {
	const [formData, setFormData] = useState({ ...filter });

	useEffect(() => {
		const convertToArray = (value) => (value ? value.split(';') : []);
		setFormData({
			...filter,
			gender: convertToArray(filter.gender),
			country: convertToArray(filter.country),
			citizenship: convertToArray(filter.citizenship),
			form_study: convertToArray(filter.form_study),
		});
	}, [filter]);

	const handleInputChange = (field, value) => {
		setFormData((prevState) => ({
			...prevState,
			[field]: value,
		}));
	};

	const handleFormSubmit = (e) => {
		e.preventDefault();
		const convertToString = (array) => array.join(';');
		const updatedData = {
			...formData,
			gender: convertToString(formData.gender),
			country: convertToString(formData.country),
			citizenship: convertToString(formData.citizenship),
			form_study: convertToString(formData.form_study),
		};
		handleSave(updatedData);
		handleClose();
	};

	return (
		<Modal open={open} onClose={handleClose}>
			<Box sx={style}>
				<Typography variant="h6" component="h2">
					Редактировать фильтр
				</Typography>
				<form onSubmit={handleFormSubmit}>
					<TextField
						label="Название"
						value={formData.name_filter || ''}
						onChange={(e) => handleInputChange('name_filter', e.target.value)}
						fullWidth
						margin="normal"
					/>
					<Autocomplete
						multiple
						options={genders}
						getOptionLabel={(option) => option.label}
						value={genders.filter((option) =>
							formData.gender?.includes(option.value)
						)}
						onChange={(event, newValue) =>
							handleInputChange(
								'gender',
								newValue.map((option) => option.value)
							)
						}
						renderInput={(params) => (
							<TextField {...params} label="Пол" sx={{ marginTop: '15px' }} />
						)}
					/>
					<Autocomplete
						multiple
						options={countries}
						getOptionLabel={(option) => option.label}
						value={countries.filter((option) =>
							formData.country?.includes(option.value)
						)}
						onChange={(event, newValue) =>
							handleInputChange(
								'country',
								newValue.map((option) => option.value)
							)
						}
						renderInput={(params) => (
							<TextField
								{...params}
								label="Страна"
								sx={{ marginTop: '15px' }}
							/>
						)}
					/>
					<Autocomplete
						multiple
						options={countries}
						getOptionLabel={(option) => option.label}
						value={countries.filter((option) =>
							formData.citizenship?.includes(option.value)
						)}
						onChange={(event, newValue) =>
							handleInputChange(
								'citizenship',
								newValue.map((option) => option.value)
							)
						}
						renderInput={(params) => (
							<TextField
								{...params}
								label="Гражданство"
								sx={{ marginTop: '15px' }}
							/>
						)}
					/>
					<Autocomplete
						multiple
						options={eduType}
						getOptionLabel={(option) => option.label}
						value={eduType.filter((option) =>
							formData.form_study?.includes(option.value)
						)}
						onChange={(event, newValue) =>
							handleInputChange(
								'form_study',
								newValue.map((option) => option.value)
							)
						}
						renderInput={(params) => (
							<TextField
								{...params}
								label="Форма обучения"
								sx={{ marginTop: '15px' }}
							/>
						)}
					/>
					<Button
						type="submit"
						variant="contained"
						color="primary"
						sx={{ marginTop: '15px' }}
					>
						Сохранить
					</Button>
				</form>
			</Box>
		</Modal>
	);
};

export default EditFilterModal;
