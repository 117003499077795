import { createTheme } from '@mui/material';
import { ThemeProvider } from '@mui/material';

export function ThemeMuiProvider({ children }) {
	const theme = createTheme({
		typography: {
			allVariants: {
				fontFamily: 'Roboto',
			},
		},
	});
	return <ThemeProvider theme={theme}>{children}</ThemeProvider>;
}
