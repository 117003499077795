import { Suspense, useEffect, useState } from 'react';
import { Route, Routes, Navigate } from 'react-router-dom';
import {
	RoutePath,
	publicRoutes,
	studentRoutes,
} from '../../../../configs/routeConfig';
import { isMobile } from 'react-device-detect';
import { LoginForm } from '../../../../widgets/LoginForm/LoginForm';
import AnalyticPage from '../../../../pages/AnalyticPage/AnalyticPage';

const AppRouter = ({ isAuthenticated }) => {
	return (
		<Suspense fallback={'...Loading'}>
			<Routes>
				{isMobile ? (
					<>
						{/* Маршруты для мобильных пользователей */}
						<Route path={RoutePath.LOGIN} element={<LoginForm />} />
						{/* {isAuthenticated && <Route path={RoutePath.ANALYTIC} element={<AnalyticPage />} />} */}
						<Route path={RoutePath.ANALYTIC} element={<AnalyticPage />} />
						<Route
							path="*"
							element={
								<Navigate
									to={isAuthenticated ? RoutePath.ANALYTIC : RoutePath.LOGIN}
									replace
								/>
							}
						/>
					</>
				) : (
					<>
						{/* Общедоступные маршруты */}
						{publicRoutes.map(({ path, Element }) => (
							<Route key={path} path={path} element={Element} />
						))}

						{/* Маршруты для аутентифицированных пользователей */}
						{isAuthenticated &&
							studentRoutes.map(({ path, Element }) => (
								<Route key={path} path={path} element={Element} />
							))}

						{/* Перенаправления для неаутентифицированных пользователей */}
						{!isAuthenticated && (
							<Route
								path="*"
								element={<Navigate to={RoutePath.LOGIN} replace />}
							/>
						)}

						{/* Перенаправления для аутентифицированных пользователей */}
						{isAuthenticated && (
							<Route
								path="*"
								element={<Navigate to={RoutePath.MAIN} replace />}
							/>
						)}
					</>
				)}
			</Routes>
		</Suspense>
	);
};

export default AppRouter;
