import Sidebar from '../widgets/Sidebar/Sidebar';
import { AppRouter } from './providers/routeProvider';
import { ThemeMuiProvider } from './providers/themeMuiProvider';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { check } from '../http/userAPI';
import { login, logout, selectUser } from '../store/userSlice';
import { jwtDecode } from 'jwt-decode';
import { useLocation, useNavigate } from 'react-router-dom';
import { isMobile } from 'react-device-detect';

function App() {
	const user = useSelector(selectUser);
	const isAuthenticated = user.isAuthenticated;
	const [isLoading, setIsLoading] = useState(true); // Добавляем новое состояние
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const location = useLocation();

	useEffect(() => {
		// Предположим, что функция check возвращает true, если токен валиден, иначе false
		check().then((data) => {
			try {
				if (data) {
					const token = localStorage.getItem('token');
					const user = jwtDecode(token)['Name']; // Предположим, что jwtDecode возвращает объект пользователя
					dispatch(login(user));
				} else {
					localStorage.removeItem('token');
					dispatch(logout());
					navigate('/login');
				}
			} catch (e) {
				console.log(e);
			}
			setIsLoading(false);
		});
	}, [dispatch, navigate]);

	const shouldShowSidebar = location.pathname !== '/login' && !isMobile;
	const appContentStyle = shouldShowSidebar
		? {}
		: { marginLeft: 0, width: '100vw' };

	if (isLoading) {
		return (
			<>
				<div className="app">
					{shouldShowSidebar && <Sidebar />}
					<div className="app__content" style={appContentStyle}>
						Загрузка...
					</div>
				</div>
			</>
		);
	}

	return (
		<ThemeMuiProvider>
			<div className="app">
				{shouldShowSidebar && <Sidebar />}
				<div className="app__content" style={appContentStyle}>
					<AppRouter isAuthenticated={isAuthenticated} />
				</div>
			</div>
		</ThemeMuiProvider>
	);
}

export default App;
