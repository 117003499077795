import React from 'react';
import cls from './HalfPageLayout.module.css';

const HalfPageLayout = ({ children, title }) => {
	return (
		<div className={cls.halfPage}>
			<h2 className={cls.halfPageTitle}>{title}</h2>
			<div className={cls.content}>{children}</div>
		</div>
	);
};

export default HalfPageLayout;
