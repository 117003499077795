// RoomDetails.js
import React, { useEffect, useRef, useState } from 'react';
import s from './RoomDetails.module.css';
import { Button, Typography, Grid,  TextField } from '@mui/material';
import { useDispatch } from 'react-redux';
import { deleteCurrentRoom, deleteStudent, fetchCurrentRoom, fetchRooms } from '../../../store/dormitorySlice';
import { ButtonOrigin } from '../../../components/button/button';
import axiosInstance from '../../../http/axiosInstanse';

const RoomDetails = ({ roomData }) => {
    const [selectedStudent, setSelectedStudent] = useState(null);
    const dispatch = useDispatch();
    const [isEditing, setIsEditing] = useState(false);
    const [editedComment, setEditedComment] = useState(roomData.comment);
    const editFieldRef = useRef(null);

    useEffect(() => {
        if (isEditing) {
            // Автофокусировка на поле при переходе в режим редактирования
            editFieldRef.current.focus();
        }
    }, [isEditing]);

    const handleStudentClick = (student) => {
        setSelectedStudent(student);
    };


    const handleBack = (id) => {
        dispatch(deleteCurrentRoom())
        dispatch(fetchRooms(id));
    };

    const handleDeleteStudent = async (id) => {
        await axiosInstance.delete(`student/${id}`)
        dispatch(deleteStudent(id))
        setSelectedStudent(null)
    }


    const handleEditClick = async() => {
        setIsEditing(!isEditing);
        // Если выходим из режима редактирования, отправляем данные на сервер
        if (isEditing) {
            await axiosInstance.put(`room/${roomData.Id}`, { comment: editedComment });
            dispatch(fetchCurrentRoom(roomData.Id))
            // Остальной код для обновления данных на сервере
        }
    };

    const handleCommentChange = (event) => {
        setEditedComment(event.target.value);
    };

    const handleSettlement = async() =>{
        console.log(roomData)
        const res = await axiosInstance.put(`calculate/room/${roomData.Id}`)
        console.log(res)
        dispatch(fetchCurrentRoom(roomData.Id))
    }
    return (
        <>
            <Button variant="contained" sx={{background: '#FF7C45' }} onClick={() => { handleBack(roomData.BuildingId) }}>
                Назад
            </Button>
            <Button variant="contained" sx={{background: '#FF7C45', marginLeft: 5 }} onClick={handleSettlement} >
                Автозаселение
            </Button>
            <Grid container>
                <Grid item xs={6}>
                    <Typography variant="h6">{`Комната ${roomData.number}`}</Typography>
                </Grid>
                <Grid item xs={6} textAlign="right">
                    <Typography variant="subtitle1">{`Поселение: ${roomData.Students.length} / ${roomData.max_count_students}`}</Typography>
                </Grid>
            </Grid>

            {/* Квадратики с именами студентов */}
            <div className={s.container}>
                <Grid container spacing={2}>
                    {roomData.Students.length != 0 ? (
                        roomData.Students.map((student) => (
                            <Grid item key={student.id}>
                                <div
                                    className={s.square}
                                    style={{}}
                                    onClick={() => handleStudentClick(student)}
                                >
                                    {student.latin_name}
                                </div>
                            </Grid>
                        ))
                    ) : (
                        <Grid item>
                            {' '}
                            <h3>Студентов нет</h3>{' '}
                        </Grid>
                    )}
                </Grid>


                {/* Информация о выбранном студенте */}
                {selectedStudent && (
                    <div className={s.info}>
                        <Typography variant="h6">{`Студент: ${selectedStudent.latin_name}`}</Typography>
                        <Typography variant="h6">{`День рождения: ${selectedStudent.birth_date}`}</Typography>
                        <Typography variant="h6">{`Номер телефона: ${selectedStudent.contact_phone_number}`}</Typography>
                        <ButtonOrigin
                            color="#FF7C45"
                            width={100}
                            onClick={() => { handleDeleteStudent(selectedStudent.id) }}>Выселить</ButtonOrigin>
                        {/* Добавьте здесь информацию о студенте, например, обучение, часы, комментарий, статус оплаты и зачисления */}
                    </div>
                )}

                {/* Комментарий к комнате */}

                <Typography variant="h6">Комментарий к комнате</Typography>
                
                {isEditing ? (

                    <TextField
                        fullWidth
                        multiline
                        rows={4}
                        variant="outlined"
                        value={editedComment}
                        onChange={handleCommentChange}
                        inputRef={editFieldRef}
                    />
                ) : (
                    <div className={s.info}>
                        <Typography sx={{margin: 1}}>
                        {roomData.comment.trim() ? roomData.comment : "Комментарий отсутствует"}
                        </Typography>
                    </div>
                )}

                <Button variant="contained" color="primary" onClick={handleEditClick} sx={{marginTop: 2}}>
                    {isEditing ? "Сохранить" : "Редактировать"}
                </Button>


            </div>
        </>
    );

};

export default RoomDetails;
