import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

export const filtersApi = createApi({
	reducerPath: 'filters',
	baseQuery: fetchBaseQuery({
		baseUrl: '/api/v1/',
		prepareHeaders: (headers) => {
			const token = localStorage.getItem('token');
			if (token) {
				headers.set('Authorization', token);
			}
			return headers;
		},
	}),
	tagTypes: ['Filter'],
	endpoints: (builder) => ({
		fetchAllFilters: builder.query({
			query: () => 'filter',
			providesTags: ['Filter'],
		}),
		deleteFilter: builder.mutation({
			query: (filterId) => ({
				url: `filter/${filterId}`,
				method: 'DELETE',
			}),
			invalidatesTags: ['Filter'],
		}),
		addFilter: builder.mutation({
			query: (filterData) => ({
				url: 'filter',
				method: 'PUT',
				body: filterData,
			}),
			invalidatesTags: ['Filter'],
		}),
		updateFilter: builder.mutation({
			query: (filterData) => ({
				url: 'filter',
				method: 'POST',
				body: filterData,
			}),
		}),
	}),
});

export const {
	useFetchAllFiltersQuery,
	useDeleteFilterMutation,
	useAddFilterMutation,
	useUpdateFilterMutation,
} = filtersApi;
