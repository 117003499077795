import React, { useEffect, useState } from 'react';
import s from './AnalyticPage.module.css'; // Путь к CSS файлу для стилизации
import {
	Button,
	FormControl,
	InputLabel,
	MenuItem,
	Paper,
	Select,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	TextField
} from '@mui/material';
import { getAllStudents, getStats } from '../../http/analyticAPI';
import { isMobile } from 'react-device-detect';
import axiosInstance from '../../http/axiosInstanse';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { logout } from '../../store/userSlice';
import MobileAnalyticTable from '../../widgets/MobileAnalyticTable/MobileAnalitycTable';

const AnalyticPage = () => {
	const [year, setYear] = useState('');
	const [dataStats, setDataStats] = useState([]);
	const [students, setStudents] = useState([]);
	const [filteredStudents, setFilteredStudents] = useState([]);
	const [lastId, setLastId] = useState(0);
	const [hasMore, setHasMore] = useState(true);

	const navigate = useNavigate(); // Используем хук для навигации
	const dispatch = useDispatch(); // Используем хук для вызова экшнов в Redux
	const [searchTerm, setSearchTerm] = useState('');


	const fetchMoreStudents = async () => {
		if (!hasMore) return;
		const newStudents = await getAllStudents(lastId + 1, 50);

		if (newStudents.length > 0) {
			setStudents((prev) => [...prev, ...newStudents]);
			setLastId(newStudents[newStudents.length - 1].id); // Предполагаем, что `id` — это поле в объекте студента
		} else {
			setHasMore(false);
		}
	};

	useEffect(() => {
		const fetchData = async () => {
			try {
				const data = await getStats();
				setDataStats(data);
			} catch (error) {
				console.error('Error fetching data: ', error);
			}
		};
		fetchData();
		fetchMoreStudents();
	}, []); // Эффект выполнится только один раз при монтировании компонента

	useEffect(() => {
		setFilteredStudents(
			students.filter(student =>
				student.russian_name.toLowerCase().includes(searchTerm.toLowerCase())
			)
		);
	}, [searchTerm, students]);

	const handleChange = (event) => {
		setYear(event.target.value);
	};


	const logoutUser = () => {
		localStorage.removeItem('token'); // Удаляем токен из localStorage
		axiosInstance.defaults.headers.common['Authorization'] = ''; // Удаляем токен из заголовка
		dispatch(logout()); // Вызываем Redux экшн для очистки состояния пользователя
		navigate('/login', { replace: true });
	};
	
	const handleSearchChange = (event) => {
		setSearchTerm(event.target.value);
	};


	return (
		<div className={s.mainContainer}>
			<div className={s.flexContainer}>
				<h2 className={s.title}>Статистика</h2>
				<FormControl variant="outlined" size="small" className={s.formControl}>
					<InputLabel className={s.inputLabel} id="year-select-label">
						Год
					</InputLabel>
					<Select
						labelId="year-select-label"
						id="year-select"
						value={year}
						className={s.select}
						onChange={handleChange}
						label="Год"
						sx={isMobile ? { width: '80px' } : {}}
					>
						<MenuItem value={2020}>2020</MenuItem>
						<MenuItem value={2021}>2021</MenuItem>
						<MenuItem value={2022}>2022</MenuItem>
						<MenuItem value={2023}>2023</MenuItem>
					</Select>
				</FormControl>
				{isMobile && (
					<Button
						variant="outlined"
						sx={{
							height: '40px',
							color: '#000',
							borderColor: '#000',
							marginLeft: 'auto',
						}}
						onClick={logoutUser}
					>
						Выйти
					</Button>
				)}
			</div>
			<div className={s.stats}>
				<Paper elevation={0} className={s.statCard}>
					<div className={s.statContainer}>
						<div className={s.statValue}>{dataStats[0]} %</div>
						<div className={s.statLabel}>Поселение</div>
					</div>
				</Paper>
				<Paper elevation={0} className={s.statCard}>
					<div className={s.statContainer}>
						<div className={s.statValue}>{dataStats[1]}</div>
						<div className={s.statLabel}>Мест свободно</div>
					</div>
				</Paper>
				<Paper elevation={0} className={s.statCard}>
					<div className={s.statContainer}>
						<div className={s.statValue}>400</div>
						<div className={s.statLabel}>Свободных комнат</div>
					</div>
				</Paper>
				<Paper elevation={0} className={s.statCard}>
					<div className={s.statContainer}>
						<div className={s.statValue}>15</div>
						<div className={s.statLabel}>Стран Поселения</div>
					</div>
				</Paper>
				<Paper elevation={0} className={s.statCard}>
					<div className={s.statContainer}>
						<div className={s.statValue}>30</div>
						<div className={s.statLabel}>Студентов оплатили проживание</div>
					</div>
				</Paper>
			</div>
			{isMobile ? (
				<>
					<MobileAnalyticTable students={students} />
					{hasMore && (
						<Button onClick={fetchMoreStudents} style={{ marginTop: 20 }}>
							Загрузить еще
						</Button>
					)}
				</>
			) : (
				
			<TableContainer
				className={s.table}
				id="scrollable-container"
				component={Paper}
			>
				<TextField
				label="Поиск по фамилии"
				variant="outlined"
				sx={{ width: '322px', margin: '10px' }}
				value={searchTerm}
				onChange={handleSearchChange}
				className={s.searchField}
			/>
				<Table aria-label="sticky table">
					<TableHead>
						<TableRow>
							<TableCell>ФИО (крат.)</TableCell>
							<TableCell>Пол</TableCell>
							<TableCell>Страна</TableCell>
							<TableCell>Статус поселения</TableCell>
							<TableCell>Номер телефона</TableCell>
						</TableRow>
					</TableHead>
						
						<TableBody>
							{filteredStudents.map((student, index) => (
								<TableRow key={index}>
									<TableCell>{student.russian_name}</TableCell>
									<TableCell>{student.gender}</TableCell>
									<TableCell>{student.country}</TableCell>
									<TableCell>{student.status}</TableCell>
									<TableCell>{student.contact_phone_number}</TableCell>
								</TableRow>
							))}
						</TableBody>
					</Table>
					{hasMore && (
						<Button onClick={fetchMoreStudents} style={{ marginTop: 20 }}>
							Загрузить еще
						</Button>
					)}
				</TableContainer>
			)}
		</div>
	);
};

export default AnalyticPage;