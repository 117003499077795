import axiosInstance from "./axiosInstanse";

export const check = async () => {
    try {
        const { data } = await axiosInstance.get('/auth/check/jwt')
        return data.isValid
    }
    catch (e) {
        console.log(e)
    }
}

export const loginUser = async (username, password) => {
    try{
    const {data} = await axiosInstance.post('auth', {
        username,
        password,
    });
    return data}


    catch(e){
        console.log(e)
    }
}