import React from 'react';
import cls from './SettingsItem.module.css';
import DeleteIcon from '@mui/icons-material/DeleteForever';
import { Button } from '@mui/material';
import { Link } from 'react-router-dom';

const SettingsItem = ({
	title,
	accessPermission,
	removeBtn,
	handleRemoveClick,
	onClickLink
}) => {
	


	const getRoleText = (accessPermission) => {
		switch (String(accessPermission)) {
			case '0':
				return 'Admin';
			case '1':
				return 'Moderator';
			case '2':
				return 'User';
			default:
				return 'Сотрудник';
		}
	};

	console.log(getRoleText(accessPermission));

	return (
		<div className={cls.container} >
			<div className={cls.link} onClick={onClickLink}>
			<div className={cls.textInfo} >
				<p className={cls.title}>{title}</p>
				{accessPermission !== undefined && (
					<p className={cls.employee}>{getRoleText(accessPermission)}</p>
				)}
			</div>
			</div>
			{removeBtn && (
				<Button
					className={cls.removeBtn}
					sx={{ color: '#000000' }}
					onClick={handleRemoveClick}
				>
					<DeleteIcon />
				</Button>
			)}
		</div>
	);
};

export default SettingsItem;
