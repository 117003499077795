import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { getDormitory } from '../http/dormitoryAPI';
import axiosInstance from '../http/axiosInstanse';

const initialState = {
	dormitories: [],
	rooms: [],
	currentRoom: null,
	selectedDormitory: {}
};

export const fetchDormitory = createAsyncThunk(
	'dormitory/fetchDormitory',
	async () => {
		const response = await axiosInstance.get('building/-1');
		console.log(response.data)
		return response.data;
	}
);

export const fetchRooms = createAsyncThunk(
	'dormitory/fetchRooms',
	async (building_id) => {
		const response = await axiosInstance.get(`building/${building_id}`);
		console.log(response.data.Rooms);
		return response.data.Rooms;
	}
);

export const fetchCurrentRoom = createAsyncThunk(
	'dormitory/fetchCurrentRoom',
	async (room_id) => {
		const response = await axiosInstance.get(`room/${room_id}`);
		console.log(response.data);
		return response.data;
	}
);
export const updateDormitory = createAsyncThunk(
	'dormitory/updateDormitory',
	async (building_data) => {
		const response = await axiosInstance.post(`building`, {
			Id: building_data.Id, 
			comment: building_data.comment,
			name: building_data.name, 
			adress: building_data.adress
		});
		console.log(response.data);
		return response.data;
	}
);

export const deleteDormitory = createAsyncThunk(
	'dormitory/deleteDormitory',
	async (id) => {
		try {
			const response = await axiosInstance.delete(`/building/${id}`); // Замените на ваш URL эндпоинта
			return response.data;
		} catch (error) {
			throw error;
		}
	}
);

export const deleteRoom = createAsyncThunk(
	'dormitory/deleteRoom',
	async (id) => {
		try {
			const response = await axiosInstance.delete(`/room/${id}`); // Замените на ваш URL эндпоинта
			return response.data;
		} catch (error) {
			throw error;
		}
	}
);

const dormitorySlice = createSlice({
	name: 'dormitory',
	initialState,
	extraReducers: (builder) => {
		builder
			.addCase(fetchDormitory.fulfilled, (state, action) => {
				state.loading = false;
				state.dormitories = action.payload;
			})
			.addCase(fetchRooms.fulfilled, (state, action) => {
				state.loading = false;
				state.rooms = action.payload;
			})
			.addCase(fetchCurrentRoom.fulfilled, (state, action) => {
				state.loading = false;
				state.currentRoom = action.payload;
			}).addCase(deleteDormitory.fulfilled, (state, action) => {
				state.loading = false;
				// Удаление общежития из списка
				console.log(action.meta.arg)
				state.dormitories = state.dormitories.filter(dormitory => dormitory.Id !== action.meta.arg);
			}).addCase(deleteRoom.fulfilled, (state, action) => {
				state.loading = false;
				console.log(action.meta.arg)
				state.rooms = state.rooms.filter(room => room.Id !== action.meta.arg);
			});;
	},
	reducers: {
		
		deleteCurrentRoom: (state, action) => {
			state.currentRoom = null;
		},
		putStudent: (state, action) => {
			state.currentRoom.Students = [
				...state.currentRoom.Students,
				action.payload,
			];
		},
        deleteStudent: (state, action) => {
			state.currentRoom.Students = state.currentRoom.Students.filter(student => student.id != action.payload)
		},
		deleteRooms: (state, action) => {state.rooms = []},
		takeDormitory: (state, action) => {state.selectedDormitory = action.payload},
		updateDormitoryField: (state, action) => {
			const { field, value } = action.payload;
			if (state.selectedDormitory && field in state.selectedDormitory) {
			  state.selectedDormitory[field] = value;
			}
		  },
		
	},
});

export const { deleteCurrentRoom, putStudent, deleteStudent, deleteRooms, takeDormitory, updateDormitoryField } = dormitorySlice.actions;

// Selector для получения списка общежитий
export const selectDormitories = (state) => state.dormitories.dormitories;
export const selectRooms = (state) => state.dormitories.rooms;
export const selectCurrentRoom = (state) => state.dormitories.currentRoom;
export const selectSelectedDormitory = (state) => state.dormitories.selectedDormitory;

export default dormitorySlice.reducer;
