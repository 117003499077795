import axiosInstance from "./axiosInstanse";

export const getStats = async () => {
    try {
        const dataRoomsStats = Math.round( ((await axiosInstance.get('statistic/percent')).data ) * 100) / 100
        const dataStudentStats = await axiosInstance.get('statistic/rooms')

        const data = [ dataRoomsStats, dataStudentStats.data,]


        return data

    }
    catch (e) {
        console.log(e)
    }
}
export const getAllStudents = async (id = 1, limit = 100) => {
    try {
        const { data } = await axiosInstance.post('student', {
            start_id: `${id}`,
            count: `${limit}`
        });

        console.log(data);
        return data;

    } catch (e) {
        console.log(e);
        return []
    }
}