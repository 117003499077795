import axios from 'axios';

const axiosInstance = axios.create({
	baseURL: '/api/configure/',
	headers: {
		'Content-Type': 'application/json',
	},
});

// Добавляем interceptor для авторизации запросов с токеном
axiosInstance.interceptors.request.use(
	(config) => {
		const token = localStorage.getItem('token');
		if (token) {
			config.headers.Authorization = `${token}`;
		}
		return config;
	},
	(error) => {
		return Promise.reject(error);
	}
);

export default axiosInstance;
