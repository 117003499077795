export const transformFormData = (formData) => {
	const transformedData = {};

	for (const [key, value] of Object.entries(formData)) {
		if (Array.isArray(value)) {
			if (value.length === 0) {
				continue; // Skip empty arrays
			} else if (value.length === 1) {
				if (typeof value[0] === 'object' && value[0].value) {
					transformedData[key] = value[0].value; // Extract value if it's an object
				} else {
					transformedData[key] = value[0]; // Convert single element arrays to string
				}
			} else {
				transformedData[key] = value
					.map((item) =>
						typeof item === 'object' && item.value ? item.value : item
					)
					.join(';'); // Join multiple values with ';'
			}
		} else {
			transformedData[key] = value;
		}
	}

	return transformedData;
};
