import React from 'react';
import DormitoryList from '../../widgets/DormitoryList/DormitoryList';
import s from './HomeScreen.module.css'; // Путь к CSS файлу для стилизации
import StudentsListFeature from '../../widgets/StudentListFeature/StudentsListFeature';
import { Button } from '@mui/material';
import axiosInstance from '../../http/axiosInstanse';

const HomeScreen = () => {
	const handleDownloadExcelClick = async () => {
		try {
			const response = await axiosInstance.get('/document/excel', {
				responseType: 'blob',
			});

			// Создаем объект Blob из полученных данных
			const blob = new Blob([response.data], {
				type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
			});

			const downloadLink = document.createElement('a');
			const url = window.URL.createObjectURL(blob);
			downloadLink.href = url;
			downloadLink.download = 'example.xlsx'; // Указываем имя файла для скачивания

			// Активируем клик по ссылке
			document.body.appendChild(downloadLink);
			downloadLink.click();

			// Освобождаем ресурсы
			window.URL.revokeObjectURL(url);
			document.body.removeChild(downloadLink);
		} catch (error) {
			console.error('Ошибка при скачивании файла:', error);
		}
	};

	return (
		<div className={s.mainScreen}>
			<Button
				variant="contained"
				sx={{
					background: '#FF7C45',
					position: 'absolute',
					top: '20px',
					right: '90px',
				}}
				onClick={handleDownloadExcelClick}
			>
				сформировать excel
			</Button>
			<StudentsListFeature />
			<DormitoryList />
		</div>
	);
};

export default HomeScreen;
