import Button from '@mui/material/Button';
import s from './button.module.css';

export function ButtonOrigin({ color, width, children, onClick }) {
	return (
		<div className={s.button}>
			<Button
				variant="contained"
				onClick={onClick}
				sx={{
					'&:hover': { backgroundColor: color },
					height: 44,
					backgroundColor: color,
					width: width,
				}}
			>
				{children}
			</Button>
		</div>
	);
}

export function ButtonText({ width, children }) {
	return (
		<div>
			<Button
				variant="text"
				sx={{
					fontSize: 12,
					height: 44,
					width: width,
				}}
			>
				{children}
			</Button>
		</div>
	);
}
