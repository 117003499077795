import React from 'react';
import {
	Typography,
	AccordionDetails,
	Accordion,
	AccordionSummary,
	Button,
	Box,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import DeleteIcon from '@mui/icons-material/DeleteForever';
import EditIcon from '@mui/icons-material/Edit';
import cls from './FiltersSettings.module.css';

const filterTranslations = {
	start_id: 'Начальный ID',
	count: 'Количество',
	russian_name: 'Русское имя',
	latin_name: 'Латинское имя',
	gender: 'Пол',
	birth_date: 'Дата рождения',
	country: 'Страна',
	citizenship: 'Гражданство',
	name_educational_institution: 'Название образовательного учреждения',
	status_1c: 'Статус 1С',
	contact_phone_number: 'Контактный номер телефона',
	first_student_email: 'Первый email студента',
	RoomId: 'ID комнаты',
	check_in_date: 'Дата заселения',
	comment: 'Комментарий',
	pass_number: 'Номер паспорта',
	form_study: 'Форма обучения',
	StatusId: 'ID статуса',
};

const statusDescriptions = {
	1: 'Нет',
	2: 'Подано заявление',
	3: 'Ожидание оплаты',
	4: 'Ожидает поселения',
	5: 'Заселён',
	6: 'Просрочена оплата',
	7: 'Переезжает',
};

const Filter = ({ filter, handleDelete, handleEdit }) => {
	const filters = Object.entries(filter);

	return (
		<Accordion style={{ border: '1px solid #000', borderRadius: '10px' }}>
			<AccordionSummary
				expandIcon={<ExpandMoreIcon />}
				aria-controls="panel1a-content"
				id="panel1a-header"
			>
				<Typography
					sx={{
						color: '#000',
						fontSize: '20px',
						fontStyle: 'normal',
						fontWeight: '600',
						lineHeight: 'normal',
						letterSpacing: '0.2px',
					}}
				>
					{filter.name_filter}
				</Typography>
			</AccordionSummary>
			<AccordionDetails>
				<div className={cls.details}>
					<div className={cls.filtersContainer}>
						{filters.map(([key, value], index) => {
							if (key === 'id' || key === 'name_filter') return null;

							const translatedKey = filterTranslations[key] || key;
							const displayValue =
								key === 'StatusId' ? statusDescriptions[value] : value;

							return (
								<div key={index} className={cls.filter}>
									<p
										className={cls.filterTitle}
									>{`${translatedKey}: ${displayValue}`}</p>
								</div>
							);
						})}
					</div>
					<Box sx={{ display: 'flex', flexDirection: 'column' }}>
						<Button
							className={cls.editBtn}
							sx={{ color: '#000000' }}
							onClick={() => handleEdit(filter)}
						>
							<EditIcon />
						</Button>
						<Button
							className={cls.removeBtn}
							sx={{ color: '#000000' }}
							onClick={handleDelete}
						>
							<DeleteIcon />
						</Button>
					</Box>
				</div>
			</AccordionDetails>
		</Accordion>
	);
};

export default Filter;
