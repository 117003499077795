import React from 'react';
import cls from './MobileDetails.module.css';

const MobileDetails = ({ student }) => {
	return (
		<div className={cls.container}>
			<p className={cls.title}>
				Комната: <span className={cls.data}>{student.RoomId}</span>
			</p>
			<p className={cls.title}>
				Телефон:{' '}
				<span className={cls.data}>{student.contact_phone_number}</span>
			</p>
		</div>
	);
};

export default MobileDetails;
