import React, { useEffect, useState } from 'react';
import SettingsLayout from '../../components/SettingsLayout/SettingsLayout';
import SettingsItem from '../../components/SettingsItem/SettingsItem';
import ConfirmationDialog from '../../components/ConfirmationDialog/ConfirmationDialog';
import { useDispatch, useSelector } from 'react-redux';
import {
	deleteEmployee,
	fetchEmployees,
	selectEmployees,
} from '../../store/employeeSlice';

const UsersSettings = () => {
	const dispatch = useDispatch();
	const employees = useSelector(selectEmployees);
	const [open, setOpen] = useState(false);
	const [selectedEmployeeId, setSelectedEmployeeId] = useState(null);

	useEffect(() => {
		dispatch(fetchEmployees());
	}, [dispatch]);

	const handleClickOpen = (employeeId) => {
		setSelectedEmployeeId(employeeId);
		setOpen(true);
	};

	const handleRemoveClick = () => {
		dispatch(deleteEmployee(selectedEmployeeId)); // Отправьте запрос на удаление
		setOpen(false);
	};

	const handleClose = (value) => {
		setOpen(false);
	};

	return (
		<SettingsLayout
			title="Пользователи"
			buttonText="Добавить пользователя"
			path={'create'}
		>
			{employees.map((employee) => (
				<SettingsItem
					key={employee.id}
					title={employee.user_name}
					accessPermission={employee.access_permission}
					removeBtn={true}
					handleRemoveClick={() => handleClickOpen(employee.user_name)}
				/>
			))}
			{/* <SettingsItem
				title="Иван Ванович Иванов"
				employee={true}
				removeBtn={true}
				handleRemoveClick={handleClickOpen}
			/> */}
			<ConfirmationDialog
				open={open}
				onClose={handleClose}
				onConfirm={handleRemoveClick}
			/>
		</SettingsLayout>
	);
};

export default UsersSettings;
