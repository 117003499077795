import { TextField } from '@mui/material';
import s from './input.module.css';
import mobile from './inputMobile.module.css';

export function InputOrigin({ children, onChange, type, value, error }) {
	return (
		<div className={s.input}>
			<TextField
				id="standard-basic"
				className={s.input}
				label={children}
				variant="standard"
				onChange={onChange}
				type={type}
				value={value}
				error={!!error}
				helperText={error}
			/>
		</div>
	);
}
