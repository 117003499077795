import React, { useState } from 'react';
import {
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	TablePagination,
	Collapse,
	TextField,
} from '@mui/material';
import MobileDetails from './MobileDetails';

const MobileAnalyticTable = ({ students }) => {
	const [openRow, setOpenRow] = useState(null);
	const [searchQuery, setSearchQuery] = useState('');

	const handleRowClick = (index) => {
		setOpenRow(openRow === index ? null : index);
	};

	const handleSearchChange = (event) => {
		setSearchQuery(event.target.value);
	};

	const filteredStudents = students.filter((student) =>
		student.russian_name.toLowerCase().includes(searchQuery.toLowerCase())
	);

	return (
		<>
			<TextField
				label="Поиск по фамилии"
				variant="outlined"
				value={searchQuery}
				onChange={handleSearchChange}
				fullWidth
				sx={{
					height: '40px',
				}}
			/>
			<TableContainer sx={{ maxHeight: 400, marginTop: '34px' }}>
				<Table>
					<TableHead>
						<TableRow>
							<TableCell>ФИО (крат.)</TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						{filteredStudents.map((student, index) => (
							<React.Fragment key={index}>
								<TableRow
									hover
									role="checkbox"
									tabIndex={-1}
									onClick={() => handleRowClick(index)}
									sx={{ cursor: 'pointer' }}
								>
									<TableCell sx={{ fontSize: '18px' }}>
										{student.russian_name}
									</TableCell>
								</TableRow>
								<TableRow>
									<TableCell sx={{ padding: '0' }}>
										<Collapse in={index === openRow}>
											<MobileDetails student={student} />
										</Collapse>
									</TableCell>
								</TableRow>
							</React.Fragment>
						))}
					</TableBody>
				</Table>
			</TableContainer>
		</>
	);
};

export default MobileAnalyticTable;
