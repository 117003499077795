import { createSlice } from '@reduxjs/toolkit';



const userSlice = createSlice({
    
    name: 'user',
    initialState: {
        isAuthenticated: false,
        username: ''
    },
    reducers: {
        login: (state, action) => {
            state.isAuthenticated = true;
            state.username = action.payload.username;
        },
        logout: (state) => {
            state.isAuthenticated = false;
            state.username = '';
        },
    },
});

export const { login, logout } = userSlice.actions;
export const selectUser = (state) => state.user;
export default userSlice.reducer;