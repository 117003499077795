import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axiosInstance from '../http/axiosInstanse';

const initialState = {
	students: [],
	loading: false,
	isFiltered: false,
	error: null,
	activeFilterId: null,
};

export const fetchStudents = createAsyncThunk(
	'students/fetchStudents',
	async ({ start_id, count }) => {
		const response = await axiosInstance.post(
			'/student', // используем относительный путь, так как baseURL уже установлен в экземпляре axios
			{
				start_id,
				count,
			}
		);
		const goodData =  await Promise.all(response.data.map(async (student) => {
			if(student.RoomId !== undefined){
			const {data} = await axiosInstance.get(`/room/${student.RoomId}`)
			const response = await axiosInstance.get(`/building/${data.BuildingId}`)
			const dopInfo = {room: data.number, floor: data.floor, adress: response.data.adress}
			return({...student, room: dopInfo.room, floor: dopInfo.floor, adress: dopInfo.adress})
			}
			else{
				return ({...student, room: '', floor: '', adress: ''})
			}
			
		}))
		
		return goodData;
	}
);

export const fetchStudentsWithFilters = createAsyncThunk(
	'students/fetchStudentsWithFilters',
	async (filters) => {
		const response = await axiosInstance.post('/student/params', filters); // предполагается, что это URL для фильтрации студентов
		return response.data; // возвращаем данные для обновления состояния
	}
);

const studentsSlice = createSlice({
	name: 'students',
	initialState,
	reducers: {
		updateStatusId: (state, action) => {
			const { id, StatusId } = action.payload;
			const studentIdToUpdate = id;
			const studentIndex = state.students.findIndex(
				(student) => student.id === studentIdToUpdate
			);

			if (studentIndex !== -1) {
				// Если студент найден в массиве, обновляем только его данные
				state.students[studentIndex] = {
					...state.students[studentIndex],
					StatusId,
				};
			}
		},
		clearStudents: (state) => {
			state.students = [];
			state.loading = false;
			state.isFiltered = false;
		},
		setActiveFilterId: (state, action) => {
			state.activeFilterId = action.payload;
		},
	},
	extraReducers: (builder) => {
		builder
			.addCase(fetchStudents.pending, (state) => {
				state.loading = true;
			})
			.addCase(fetchStudents.fulfilled, (state, action) => {
				state.loading = false;
				state.students = [...state.students, ...action.payload];
			})
			.addCase(fetchStudents.rejected, (state, action) => {
				state.loading = false;
				state.error = action.error.message;
			})
			.addCase(fetchStudentsWithFilters.pending, (state) => {
				state.loading = true;
			})
			.addCase(fetchStudentsWithFilters.fulfilled, (state, action) => {
				state.loading = false;
				state.students = action.payload;
				state.isFiltered = true; // Сбрасываем флаг isFiltered после успешного получения данных
			})
			.addCase(fetchStudentsWithFilters.rejected, (state, action) => {
				state.loading = false;
				state.error = action.error.message;
				state.isFiltered = true; // Сбрасываем флаг isFiltered в случае ошибки
			});
	},
});

export default studentsSlice.reducer;

export const { updateStatusId, clearStudents, setActiveFilterId } =
	studentsSlice.actions;
