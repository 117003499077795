import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
	clearStudents,
	fetchStudents,
	setActiveFilterId,
} from '../../store/studentsSlice';
import {
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	TablePagination,
	Collapse,
	TextField,
} from '@mui/material';
import StudentInfo from '../../components/StudentInfo/StudentInfo';
import HalfPageLayout from '../../components/HalfPageLayout/HalfPageLayout';
import cls from './StudentsListFeature.css';
import StudentsFilterButton from '../../components/StudentsFilterButton/StudentsFilterButton';

const columns = [
	{ id: 'latin_name', label: 'ФИО (лат.)', minWidth: 200 },
	{ id: 'gender', label: 'Пол', minWidth: 60 },
	{ id: 'country', label: 'Страна', minWidth: 100 },
	{
		id: 'StatusId',
		label: 'Статус поселения',
		minWidth: 100,
		align: 'center',
	},
];

const statusDescriptions = {
	1: 'Нет',
	2: 'Подано заявление',
	3: 'Ожидание оплаты',
	4: 'Ожидает поселения',
	5: 'Заселён',
	6: 'Просрочена оплата',
	7: 'Переезжает',
};

const StudentsListFeature = () => {
	const dispatch = useDispatch();
	const { students, loading, error, isFiltered } = useSelector(
		(state) => state.students
	);
	const [page, setPage] = useState(0);
	const [rowsPerPage, setRowsPerPage] = useState(10);
	const [openRow, setOpenRow] = useState(null);
	const [filteredStudents, setFilteredStudents] = useState([]);
	const [searchTerm, setSearchTerm] = useState('');

	useEffect(() => {
		if (!isFiltered) {
			const startId = page * rowsPerPage * 2 + 34;
			const maxPage = Math.ceil(students.length / rowsPerPage) - 1;

			if (page === maxPage || students.length === 0) {
				dispatch(
					fetchStudents({
						start_id: startId,
						count: rowsPerPage * 2,
					})
				);
			}
		}
	}, [page, rowsPerPage, dispatch, students.length, isFiltered]);

	useEffect(() => {
		const filtered = students.filter(student =>
			student.latin_name.toLowerCase().includes(searchTerm.toLowerCase())
		);
		setFilteredStudents(filtered);
	}, [searchTerm, students]);

	const handleChangePage = (event, newPage) => {
		setPage(newPage);
	};

	const handleChangeRowsPerPage = (event) => {
		setRowsPerPage(parseInt(event.target.value, 10));
		setPage(0);
	};

	const handleRowClick = (index) => {
		setOpenRow(openRow === index ? null : index);
	};

	const studentsToDisplay = searchTerm ? filteredStudents.slice(
		page * rowsPerPage,
		(page + 1) * rowsPerPage
	) : students.slice(
		page * rowsPerPage,
		(page + 1) * rowsPerPage
	);

	const resetFilters = () => {
		dispatch(clearStudents());
		dispatch(
			fetchStudents({
				start_id: 34,
				count: rowsPerPage * 2,
			})
		);
		dispatch(setActiveFilterId(null));
		setSearchTerm('');
	};

	const handleSearchChange = (event) => {
		setSearchTerm(event.target.value);
		setPage(0);
	};

	return (
		<HalfPageLayout title="Список студентов">
			<div className={cls.searchBlock}>
				<TextField
					label="Поиск"
					variant="outlined"
					sx={{ width: '322px' }}
					value={searchTerm}
					onChange={handleSearchChange}
				/>
				<StudentsFilterButton
					setPage={() => setPage(0)}
					resetFilters={resetFilters}
				/>
			</div>
			<TableContainer sx={{ maxHeight: 587, marginTop: '34px' }}>
				<Table>
					<TableHead>
						<TableRow>
							{columns.map((column) => (
								<TableCell
									key={column.id}
									align={column.align}
									style={{
										minWidth: column.minWidth,
										padding: '2px 16px',
										fontWeight: 700,
									}}
								>
									{column.label}
								</TableCell>
							))}
						</TableRow>
					</TableHead>
					<TableBody>
						{studentsToDisplay.map((student, index) => (
							<React.Fragment key={student.id}>
								<TableRow
									hover
									role="checkbox"
									tabIndex={-1}
									onClick={() => handleRowClick(index)}
									sx={{ cursor: 'pointer' }}
								>
									{columns.map((column) => {
										const value = student[column.id];
										let displayValue = value;
										if (column.id === 'StatusId') {
											displayValue = statusDescriptions[value] || value;
										}
										return (
											<TableCell
												key={column.id}
												align={column.align}
												sx={{ borderBottom: 'none', fontSize: '16px' }}
											>
												{displayValue}
											</TableCell>
										);
									})}
								</TableRow>
								<TableRow>
									<TableCell colSpan={columns.length} sx={{ padding: '0' }}>
										<Collapse in={index === openRow}>
											<StudentInfo student={student} />
										</Collapse>
									</TableCell>
								</TableRow>
							</React.Fragment>
						))}
					</TableBody>
				</Table>
			</TableContainer>
			<TablePagination
				rowsPerPageOptions={[10, 20]}
				component="div"
				count={searchTerm ? filteredStudents.length : students.length}
				rowsPerPage={rowsPerPage}
				page={page}
				onPageChange={handleChangePage}
				onRowsPerPageChange={handleChangeRowsPerPage}
			/>
		</HalfPageLayout>
	);
};

export default StudentsListFeature;