import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axiosInstance from '../http/axiosInstanceForSettings';

export const fetchEmployees = createAsyncThunk(
	'employees/fetchEmployees',
	async () => {
		try {
			const response = await axiosInstance.get('/auth/user'); // Замените на ваш URL эндпоинта
			return response.data;
		} catch (error) {
			throw error;
		}
	}
);

export const deleteEmployee = createAsyncThunk(
	'employees/deleteEmployee',
	async (username) => {
		try {
			const response = await axiosInstance.delete('/auth/user', {
				data: { username },
			}); // Замените на ваш URL эндпоинта
			return response.data;
		} catch (error) {
			throw error;
		}
	}
);

// Создаем слайс
const employeeSlice = createSlice({
	name: 'employees',
	initialState: {
		employees: [],
		status: 'idle', // idle, loading, succeeded, failed
		error: null,
	},
	reducers: {},
	extraReducers: (builder) => {
		builder
			.addCase(fetchEmployees.pending, (state) => {
				state.status = 'loading';
			})
			.addCase(fetchEmployees.fulfilled, (state, action) => {
				state.status = 'succeeded';
				state.employees = action.payload;
			})
			.addCase(fetchEmployees.rejected, (state, action) => {
				state.status = 'failed';
				state.error = action.error.message;
			})
			.addCase(deleteEmployee.pending, (state) => {
				state.status = 'loading';
			})
			.addCase(deleteEmployee.fulfilled, (state, action) => {
				state.status = 'succeeded';
				// Обновите список работников, исключив удаленного
				state.employees = state.employees.filter(
					(employee) => employee.user_name !== action.meta.arg
				);
			})
			.addCase(deleteEmployee.rejected, (state, action) => {
				state.status = 'failed';
				state.error = action.error.message;
			});
	},
});

// Экспортируем экшены и селекторы
// export const {} = employeeSlice.actions;
export const selectEmployees = (state) => state.employees.employees;
export const selectStatus = (state) => state.employees.status;
export const selectError = (state) => state.employees.error;

// Экспортируем редьюсер
export default employeeSlice.reducer;
