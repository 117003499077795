import { Box, Typography, IconButton } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import ClearIcon from '@mui/icons-material/Clear';
import { useDispatch, useSelector } from 'react-redux';
import {
	fetchStudentsWithFilters,
	setActiveFilterId,
} from '../../store/studentsSlice';

const FilterPreset = ({ filter, resetFilters }) => {
	const dispatch = useDispatch();
	const activeFilterId = useSelector((state) => state.students.activeFilterId);
	const isActive = activeFilterId === filter.id;

	const applyFilter = () => {
		if (isActive) {
			resetFilters();
			dispatch(setActiveFilterId(null));
		} else {
			resetFilters();
			dispatch(fetchStudentsWithFilters(filter));
			dispatch(setActiveFilterId(filter.id));
		}
	};

	return (
		<Box
			sx={{
				border: '1px solid #000',
				padding: '5px',
				borderRadius: '15px',
				display: 'flex',
				alignItems: 'center',
			}}
		>
			<Typography>{filter.name_filter}</Typography>
			<IconButton size="small" onClick={applyFilter}>
				{isActive ? (
					<ClearIcon fontSize="inherit" />
				) : (
					<AddIcon fontSize="inherit" />
				)}
			</IconButton>
		</Box>
	);
};

export default FilterPreset;
