import { ButtonOrigin } from '../../components/button/button';
import { InputOrigin } from '../../components/input/input';
import { Text } from '../../components/text/text';
import s from './LoginForm.module.css';
import mobile from './LoginFormMobile.module.css';
import { login } from '../../store/userSlice';
import { useDispatch } from 'react-redux';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom'; // Импортируем useNavigate
import { loginUser } from '../../http/userAPI';
import { jwtDecode } from 'jwt-decode';
import { isMobile } from 'react-device-detect';

export const LoginForm = () => {
	const dispatch = useDispatch();

	const [username, setUsername] = useState('');
	const [password, setPassword] = useState('');
	const [errors, setErrors] = useState({ username: '', password: '' });
	const navigate = useNavigate();

	const styles = isMobile ? mobile : s;

	const validateForm = () => {
		let tempErrors = { username: '', password: '' };
		let formIsValid = true;

		// Проверка на пустоту логина
		if (!username) {
			formIsValid = false;
			tempErrors['username'] = 'Не может быть пустым';
		} else if (!username.match(/^[a-zA-Z0-9-_]{3,20}$/)) {
			// Проверка на формат логина, если он не пустой
			formIsValid = false;
			tempErrors['username'] = 'Недопустимый логин';
		}

		// // Проверка на пустоту пароля
		// if (!password) {
		//     formIsValid = false;
		//     tempErrors["password"] = "Не может быть пустым";
		// } else if (!password.match(/^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{8,}$/)) {
		//     // Проверка на формат пароля, если он не пустой
		//     formIsValid = false;
		//     tempErrors["password"] = "Минимум 8 символов";
		// }

		setErrors(tempErrors);
		return formIsValid;
	};

	const handleLogin = async (e) => {
		e.preventDefault();
		if (!validateForm()) {
			return;
		}

		try {
			const token = await loginUser(username, password);
			localStorage.setItem('token', token);
			const user = jwtDecode(token)['Name'];
			dispatch(login({ username: user })); // Используйте правильные поля из вашего JWT
			navigate('/');
			console.log(token);
		} catch (error) {
			console.log('Login error', error);
			// Устанавливаем ошибку в состояние, чтобы отобразить пользователю
			setErrors({ ...errors, form: 'Ошибка логина' });
		}
	};
	return (
		<div className={styles.form}>
			<div className={styles.container}>
				{isMobile || <Text>Введите логин</Text>}
				<InputOrigin
					value={username}
					onChange={(e) => setUsername(e.target.value)}
					error={errors.username}
				>
					Логин
				</InputOrigin>
				{isMobile || <Text>Введите пароль</Text>}
				<InputOrigin
					value={password}
					onChange={(e) => setPassword(e.target.value)}
					type="password"
					error={errors.password}
				>
					Пароль
				</InputOrigin>
				<ButtonOrigin
					width={isMobile ? 200 : 400}
					color={'#FF7C45'}
					onClick={handleLogin}
				>
					Войти
				</ButtonOrigin>
			</div>
		</div>
	);
};
