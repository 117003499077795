import React, { useEffect, useState } from 'react';
import SettingsLayout from '../../components/SettingsLayout/SettingsLayout';
import { useDispatch, useSelector } from 'react-redux';
import { deleteDormitory, deleteRooms, fetchDormitory, selectDormitories, takeDormitory } from '../../store/dormitorySlice';
import SettingsItem from '../../components/SettingsItem/SettingsItem';
import ConfirmationDialog from '../../components/ConfirmationDialog/ConfirmationDialog';
import { useNavigate } from 'react-router-dom';

const DormitoriesSettings = () => {
	const dormitories = useSelector(selectDormitories)
	const dispatch = useDispatch();
	const [open, setOpen] = useState(false);
	const [selectedDormitoryId, setSelectedDormitoryId] = useState(null);
	const navigate = useNavigate();

	

	useEffect(() => {
		dispatch(fetchDormitory());
		dispatch(deleteRooms());
	}, [dispatch]);

	const handleClickOpen = (dormitoryId) => {
		setSelectedDormitoryId(dormitoryId);
		setOpen(true);
	};

	const handleRemoveClick = () => {
		dispatch(deleteDormitory(selectedDormitoryId)); // Отправьте запрос на удаление
		setOpen(false);
	};

	const handleClose = (value) => {
		setOpen(false);
	};
	const handleClickLink = (e) => {
		console.log("----------------------------------------------")
		console.log(e)
		
    	dispatch(takeDormitory(e));
		
   		navigate('edit'); 
	};


	return (
		<SettingsLayout title="Настройка общежитий" buttonText="Добавить общежитие" path="create">
			{dormitories.map((dormitory) => (
				<SettingsItem
					key={dormitory.Id}
					title={dormitory.name}
					removeBtn={true}
					handleRemoveClick={() => handleClickOpen(dormitory.Id)}
					onClickLink={() => handleClickLink(dormitory)}

				/>


			))}

			<ConfirmationDialog
				open={open}
				onClose={handleClose}
				onConfirm={handleRemoveClick}
			/>
		</SettingsLayout>
	);
};

export default DormitoriesSettings;
