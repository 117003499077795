import { Popper } from '@mui/base';
import {
	Box,
	Button,
	Checkbox,
	FormControlLabel,
	FormGroup,
	Typography,
} from '@mui/material';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import {
	fetchStudentsWithFilters,
	setActiveFilterId,
} from '../../store/studentsSlice';
import { useFetchAllFiltersQuery } from '../../store/filtersApi';
import FilterPreset from './FilterPreset';

const StudentsFilterButton = ({ setPage, resetFilters }) => {
	const [anchorEl, setAnchorEl] = useState(null);
	const [isMale, setIsMale] = useState(false);
	const [isFemale, setIsFemale] = useState(false);
	const [isResident, setIsResident] = useState(false);
	const [isNotResident, setIsNotResident] = useState(false);
	const dispatch = useDispatch();
	const { data } = useFetchAllFiltersQuery();

	const handleClick = (event) => {
		setAnchorEl(anchorEl ? null : event.currentTarget);
	};

	const open = Boolean(anchorEl);
	const id = open ? 'simple-popper' : undefined;

	const applyFilters = () => {
		let filters = {};

		// Создаем строку для пола, если выбраны соответствующие чекбоксы
		const genderValue = [isMale ? 'М' : null, isFemale ? 'Ж' : null]
			.filter(Boolean)
			.join(';');

		// Добавляем поле gender, только если строка не пустая
		if (genderValue) {
			filters.gender = genderValue;
		}

		// Создаем строку для статуса поселения, аналогично
		const statusValue = [isResident ? '5' : null, isNotResident ? '1' : null]
			.filter(Boolean)
			.join(';');

		// Добавляем поле StatusId, только если строка не пустая
		if (statusValue) {
			filters.StatusId = statusValue;
		}
		console.log(filters);
		// Отправляем запрос с фильтрами
		dispatch(fetchStudentsWithFilters(filters));
		dispatch(setActiveFilterId(null));
		setPage();
	};

	const resetFiltersClick = () => {
		setIsMale(false);
		setIsFemale(false);
		setIsNotResident(false);
		setIsResident(false);
		setPage();
		resetFilters();
	};

	return (
		<>
			<Button
				variant="contained"
				sx={{ width: '128px', background: '#FF7C45' }}
				onClick={handleClick}
				aria-describedby={id}
			>
				Параметры
			</Button>
			<Popper id={id} open={open} anchorEl={anchorEl}>
				<Box
					sx={{
						width: '300px',
						height: '400px',
						backgroundColor: '#FFF',
						border: '1px solid #000',
						borderRadius: '10px',
						mt: 1,
						padding: '20px',
					}}
				>
					<Box>
						<Typography>Готовые шаблоны</Typography>
						<Box
							sx={{
								display: 'flex',
								flexWrap: 'wrap',
								gap: '5px',
								maxHeight: '90px',
								overflowY: 'auto',
							}}
						>
							{data &&
								data.map((filter) => (
									<FilterPreset
										key={filter.id}
										filter={filter}
										resetFilters={resetFiltersClick}
									/>
								))}
						</Box>
					</Box>
					<Box>
						<FormGroup>
							<FormControlLabel
								control={
									<Checkbox
										checked={isMale}
										onChange={() => setIsMale(!isMale)}
									/>
								}
								label="Мужской"
							/>
							<FormControlLabel
								control={
									<Checkbox
										checked={isFemale}
										onChange={() => setIsFemale(!isFemale)}
									/>
								}
								label="Женский"
							/>
							<FormControlLabel
								control={
									<Checkbox
										checked={isResident}
										onChange={() => setIsResident(!isResident)}
									/>
								}
								label="Поселен"
							/>
							<FormControlLabel
								control={
									<Checkbox
										checked={isNotResident}
										onChange={() => setIsNotResident(!isNotResident)}
									/>
								}
								label="Не поселен"
							/>
						</FormGroup>
					</Box>
					<Button
						variant="contained"
						sx={{ background: '#FF7C45' }}
						onClick={applyFilters}
					>
						Применить фильтры
					</Button>
					<Button
						variant="contained"
						sx={{ background: '#FF7C45', marginTop: '10px' }}
						onClick={resetFiltersClick}
					>
						Сбросить фильтры
					</Button>
				</Box>
			</Popper>
		</>
	);
};

export default StudentsFilterButton;
