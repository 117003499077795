import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import cls from './ConfirmationDialog.module.css';
import { Button } from '@mui/material';

const ConfirmationDialog = (props) => {
	const { onClose, onConfirm, selectedValue, open } = props;

	const handleClose = () => {
		onClose(selectedValue);
	};

	return (
		<Dialog onClose={handleClose} open={open}>
			<DialogTitle className={cls.title}>Вы уверены?</DialogTitle>
			<div className={cls.container}>
				<Button
					variant="outlined"
					onClick={handleClose}
					sx={{
						margin: '30px',
						width: '140px',
						height: '50px',
						color: '#000',
						borderColor: '#000',
						textTransform: 'none',
						fontSize: '15px',
						'&:hover': {
							borderColor: 'var(--orange-color)',
						},
					}}
				>
					Отмена
				</Button>
				<Button
					variant="outlined"
					onClick={onConfirm}
					sx={{
						margin: '30px',
						width: '140px',
						height: '50px',
						color: '#000',
						borderColor: '#000',
						textTransform: 'none',
						fontSize: '15px',
						'&:hover': {
							borderColor: 'var(--orange-color)',
						},
					}}
				>
					Удалить
				</Button>
			</div>
		</Dialog>
	);
};

export default ConfirmationDialog;
