import React from 'react';
import { Link } from 'react-router-dom';
import cls from './Sidebar.module.css';
import TrendingUpIcon from '@mui/icons-material/TrendingUp';
import DomainIcon from '@mui/icons-material/Domain';
import SettingsIcon from '@mui/icons-material/Settings';
import classNames from 'classnames';
import { AppRoutes } from '../../configs/routeConfig';

const Sidebar = () => {
	return (
		<div className={cls.Sidebar}>
			<div className={cls.links}>
				<Link className={cls.link}
				to={AppRoutes.ANALYTIC}
				>
					<TrendingUpIcon className={cls.icon} />
				</Link>
				<Link className={cls.link}
					to={AppRoutes.MAIN}
				>
					<DomainIcon className={cls.icon} />
				</Link>
			</div>
			<Link
				className={classNames(cls.link, cls.settings)}
				to={AppRoutes.SETTINGS}
			>
				<SettingsIcon className={cls.icon} />
			</Link>
		</div>
	);
};

export default Sidebar;
