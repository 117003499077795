import React, { useState } from 'react';
import SettingsLayout from '../../components/SettingsLayout/SettingsLayout';
import cls from './FiltersSettings.module.css';
import ConfirmationDialog from '../../components/ConfirmationDialog/ConfirmationDialog';
import EditFilterModal from './EditFilterModal';
import {
	useDeleteFilterMutation,
	useFetchAllFiltersQuery,
	useUpdateFilterMutation,
} from '../../store/filtersApi';
import Filter from './Filter';
import { transformFormData } from '../../pages/CreateFilterPage/transformData';

const FiltersSettings = () => {
	const [open, setOpen] = useState(false);
	const [selectedFilter, setSelectedFilter] = useState(null);
	const [editModalOpen, setEditModalOpen] = useState(false);

	const { data } = useFetchAllFiltersQuery();
	const [deleteFilter] = useDeleteFilterMutation();
	const [updateFilter] = useUpdateFilterMutation();

	const handleClickOpen = (filterId) => {
		setOpen(true);
		setSelectedFilter(filterId);
	};

	const handleClose = (value) => {
		setOpen(false);
	};

	const handleRemoveClick = async () => {
		try {
			await deleteFilter(selectedFilter).unwrap();
			setOpen(false);
		} catch (error) {
			console.log(error);
		}
	};

	const handleEditClick = (filter) => {
		setSelectedFilter(filter);
		setEditModalOpen(true);
	};

	const handleEditClose = () => {
		setEditModalOpen(false);
	};

	const handleSaveEdit = async (updatedFilter) => {
		try {
			console.log(transformFormData(updatedFilter));
			await updateFilter(transformFormData(updatedFilter)).unwrap();
			setEditModalOpen(false);
		} catch (error) {
			console.log(error);
		}
	};

	return (
		<SettingsLayout
			title="Наборы фильтров"
			buttonText="Добавить фильтр"
			path="create"
		>
			<div className={cls.filters}>
				{data &&
					data.map((filter) => (
						<Filter
							key={filter.id}
							filter={filter}
							handleDelete={() => handleClickOpen(filter.id)}
							handleEdit={handleEditClick}
						/>
					))}
			</div>
			<ConfirmationDialog
				open={open}
				onClose={handleClose}
				onConfirm={handleRemoveClick}
			/>
			{selectedFilter && (
				<EditFilterModal
					open={editModalOpen}
					handleClose={handleEditClose}
					filter={selectedFilter}
					handleSave={handleSaveEdit}
				/>
			)}
		</SettingsLayout>
	);
};

export default FiltersSettings;
